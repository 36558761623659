import { combineReducers } from 'redux';

import authentication from 'store/reducers/authentication';
import searchQuery from 'store/reducers/searchQueryReducer';
import quickSearch from 'store/reducers/quickSearchReducer';
import details from 'store/reducers/DetailsReducer/detailsReducer';
import modal from 'store/reducers/globalModalReducer';
import documentUploader from 'store/reducers/documentUploaderReducer';
import documentPanel from 'store/reducers/documentPanelReducer';
import notes from 'store/reducers/notesReducer';
import formBuilder from 'store/reducers/formBuilderReducer';
import newRecord from 'store/reducers/newRecordReducer';
import companySelect from 'store/reducers/companySelectReducer';
import epcSelect from 'store/reducers/epcSelectReducer';
import maps from 'store/reducers/mapsReducer';
import searchResults from 'store/reducers/searchReducer';
import searchStatistics from 'store/reducers/searchStatisticsReducer';
import filters from 'store/reducers/filtersReducer';
import propertyInterests from 'store/reducers/propertyInterestsReducer';
import tableConfig from 'store/reducers/tableConfigReducer';
import featureFlags from 'feature_flags/featureFlagReducer';
import portfolioNameSelect from 'store/reducers/portfolioNameSelectReducer';
import pollingUpdate from 'store/reducers/pollingUpdateReducer';
import pollingNewRecord from 'store/reducers/pollingNewRecordReducer';
import exportSearchResults from 'store/reducers/exportSearchResultsReducer';
import userQueries from 'store/reducers/userQueriesReducer';
import userProfile from 'store/reducers/userProfileReducer';
import telemetryStatistics from 'store/reducers/telemetryStatisticsReducer';
import usersList from 'store/reducers/usersListReducer';
import debts from 'store/reducers/debtsReducer';
import valuations from 'store/reducers/valuationsReducer';
import relatedParty from 'store/reducers/relatedPartyReducer';
import athenaUser from 'store/reducers/athenaUserReducer';
import companyGetDeletionData from './reducers/companyGetDeletionDataReducer';
import scheduleTeamQuery from './reducers/scheduleTeamQueryReducer';
import fullScreenImage from './reducers/fullScreenImageReducer';
import primaryImage from './reducers/primaryImageReducer';

interface GlobalState {
  account: {};
}

const initialGlobalState = {
  account: {},
};

function global(state = initialGlobalState): GlobalState {
  return state;
}

const rootReducer = combineReducers({
  global,
  searchQuery,
  authentication,
  quickSearch,
  details,
  documentUploader,
  documentPanel,
  formBuilder,
  modal,
  newRecord,
  companySelect,
  epcSelect,
  maps,
  searchResults,
  searchStatistics,
  filters,
  propertyInterests,
  tableConfig,
  featureFlags,
  notes,
  portfolioNameSelect,
  pollingUpdate,
  pollingNewRecord,
  exportSearchResults,
  userQueries,
  userProfile,
  telemetryStatistics,
  usersList,
  debts,
  valuations,
  relatedParty,
  athenaUser,
  companyGetDeletionData,
  scheduleTeamQuery,
  fullScreenImage,
  primaryImage,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
