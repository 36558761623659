import React from 'react';

import { globalVariables } from 'globalConstants';
import { OptionGroup, Option } from 'components/Forms/types';
import { ValidationProps } from 'connected/FormFlow/types';
import { countrySelectCopy } from './constants';
import countries from './countries.json';
import SelectSearch from '../SelectSearch/SelectSearch';
import { getSelectedCountries } from './helpers';

export interface CountrySelectProps {
  onChange: (option: Option) => void;
  selectedValue?: string;
  name?: string;
  validation?: ValidationProps;
  isDisabled?: boolean;
  onDelete?: () => void;
}

const CountrySelect: React.FC<CountrySelectProps> = ({
  onChange,
  selectedValue,
  validation,
  isDisabled,
  onDelete,
  name,
}: CountrySelectProps) => {
  const countryList = (countries as unknown) as OptionGroup[];

  const mergedCountries = getSelectedCountries(countryList, selectedValue);

  return (
    <SelectSearch
      id={name ?? 'country-select'}
      name={name ?? 'country-select'}
      options={countries}
      label={countrySelectCopy.label}
      onChange={onChange}
      multipleSelect={false}
      maxWidth={globalVariables.SELECT_MAX_WIDTH}
      placeholder=""
      validation={validation}
      selectedOptions={mergedCountries}
      isDisabled={isDisabled}
      onDelete={onDelete}
    />
  );
};

export default CountrySelect;
