import { AuthenticatedRequestObservable } from 'apis/request';
import {
  EpcSelectActionTypes,
  EpcSelectQueryAction,
} from 'connected/EpcSelect/types';
import { endpoints, QUICKSEARCH_RESULT_LIMIT } from 'globalConstants';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';
import {
  epcSelectApiFail,
  epcSelectApiSuccess,
} from 'store/actions/epcSelectActions';

type EpcSelectEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const epcSelectEpic: Epic = (
  action$,
  state$,
  { authRequest }: EpcSelectEpicDependencies,
) =>
  action$.pipe(
    ofType(EpcSelectActionTypes.EPC_SELECT_QUERY),
    debounceTime(300),
    filter(
      (action: EpcSelectQueryAction) => action.payload.searchTerm.length >= 3,
    ),
    mergeMap((action: EpcSelectQueryAction) => {
      const { searchTerm, page } = action.payload;

      const encodedTerm = encodeURIComponent(searchTerm);
      const url = endpoints.epcSearch(
        encodedTerm,
        QUICKSEARCH_RESULT_LIMIT,
        page,
      );

      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((ajaxResponse) => {
          if (ajaxResponse.status !== 200) {
            throw new Error(`error loading ${url}`);
          }
          return epcSelectApiSuccess(ajaxResponse.response);
        }),
      );
    }),
    catchError((error) => of(epcSelectApiFail(error))),
  );

export default epcSelectEpic;
