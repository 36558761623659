import {
  EpcSelectActions,
  EpcSelectActionTypes,
  EpcSelectResult,
} from 'connected/EpcSelect/types';

export interface EpcSelectState {
  isFetching: boolean;
  result: EpcSelectResult;
  searchTerm: string;
  error?: string;
}

export const initialState: EpcSelectState = {
  isFetching: false,
  searchTerm: '',
  result: {
    results: [],
  },
};

const epcSelectReducer = (
  state = initialState,
  action: EpcSelectActions,
): EpcSelectState => {
  switch (action.type) {
    case EpcSelectActionTypes.EPC_SELECT_QUERY: {
      const { searchTerm } = action.payload;

      return {
        ...state,
        isFetching: !!searchTerm.length && searchTerm.length >= 3,
        searchTerm,
      };
    }

    case EpcSelectActionTypes.EPC_SELECT_API_SUCCESS: {
      const { results: data } = action.payload;
      const pageNumber = action.payload.meta?.page ?? 0;
      const shouldAppendResults = pageNumber > 0;
      return {
        ...state,
        isFetching: false,
        result: {
          results: shouldAppendResults
            ? [...state.result.results, ...data]
            : data,
          meta: action.payload.meta,
        },
      };
    }

    case EpcSelectActionTypes.EPC_SELECT_API_FAIL: {
      return {
        ...state,
        isFetching: false,
        result: { results: [] },
        error: action.payload.error,
      };
    }

    case EpcSelectActionTypes.EPC_SELECT_RESET: {
      return {
        ...state,
        isFetching: false,
        searchTerm: '',
        result: { results: [] },
      };
    }

    default:
      return state;
  }
};

export default epcSelectReducer;
