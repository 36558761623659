import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PanelWrapper from 'components/PanelWrapper/PanelWrapper';
import {
  getUsersListFetching,
  getUsersListResults,
} from 'store/selectors/usersListSelectors';
import { getUsersListAction } from 'store/actions/usersListActions';
import { globalContent } from 'globalConstants';
import { LoadingSpinner } from 'components/Spinner/Spinner';
import { ADMIN_USERS_LIST_COPY } from './constants';
import AdminUsersPanelTable from './AdminUsersPanelTable';

const AdminUsers: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsersListAction());
  }, [dispatch]);

  const usersListIsFetching = useSelector(getUsersListFetching);
  const usersListResult = useSelector(getUsersListResults);

  return (
    <PanelWrapper
      title={ADMIN_USERS_LIST_COPY.title}
      id="usersListPanel"
      anchorId="usersList"
    >
      {usersListIsFetching ? (
        <>
          <LoadingSpinner />
          {globalContent.loading}
        </>
      ) : (
        <AdminUsersPanelTable users={usersListResult} />
      )}
    </PanelWrapper>
  );
};

export default AdminUsers;
