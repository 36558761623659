import { GlobalApiFailError } from 'store/actions/types';

export enum AddEpcActionTypes {
  ADD_EPC = 'ADD_EPC',
  ADD_EPC_SUCCESS = 'ADD_EPC_SUCCESS',
  ADD_EPC_FAIL = 'ADD_EPC_FAIL',
}

export type AddEpcActionPayload = {
  epcIds: string[];
  buildingId: string;
};

export type AddEpcAction = {
  type: AddEpcActionTypes.ADD_EPC;
  payload: AddEpcActionPayload;
};

export type AddEpcSuccessAction = {
  type: AddEpcActionTypes.ADD_EPC_SUCCESS;
};

export type AddEpcFailAction = {
  type: AddEpcActionTypes.ADD_EPC_FAIL;
  payload: GlobalApiFailError;
};

export type AddEpcActions =
  | AddEpcAction
  | AddEpcSuccessAction
  | AddEpcFailAction;
