import React from 'react';

import {
  CellItemLink,
  CellItemLinkText,
  CellItemLinkWrapper,
} from 'connected/BasePanel/BasePanelStyled';
import { IndividualCompany } from 'connected/InvestmentPanel/types';

import { Vertical, urls } from 'globalConstants';
import CRMLink from 'components/CallToActions/CRMLink/CRMLink';
import CRMNearestLink from 'components/CallToActions/CRMNearestLink/CRMNearestLink';
import { IntrohiveRSSPill } from 'components/CallToActions/IntrohivePill/IntrohivePill';

export const mapCompanyCells = (
  companies?: IndividualCompany[],
  isAddable?: boolean,
) => {
  if (isAddable && companies?.length === 0) {
    return null;
  }

  return (
    <ul>
      {companies?.length ? (
        companies.map(
          ({
            companyId,
            companyName,
            savCrmId,
            savCrmNearestId,
            isPrivate,
            introhiveRelationshipStrengthScore,
            introhiveNearestRelationshipStrengthScore,
            introhiveScoreId,
          }) => (
            <li key={companyId}>
              {isPrivate ? (
                <CellItemLinkText>{companyName}</CellItemLinkText>
              ) : (
                <CellItemLinkWrapper>
                  <CellItemLink
                    to={urls.searchDetails(Vertical.Company, companyId)}
                  >
                    <CellItemLinkText>{companyName}</CellItemLinkText>
                  </CellItemLink>
                  {savCrmId && <CRMLink crmId={savCrmId} />}
                  {savCrmNearestId && (
                    <CRMNearestLink crmNearestId={savCrmNearestId} />
                  )}
                  {(introhiveRelationshipStrengthScore ||
                    introhiveNearestRelationshipStrengthScore) && (
                    <IntrohiveRSSPill
                      value={introhiveRelationshipStrengthScore}
                      valueNearest={introhiveNearestRelationshipStrengthScore}
                      introhiveScoreId={introhiveScoreId}
                    />
                  )}
                </CellItemLinkWrapper>
              )}
            </li>
          ),
        )
      ) : (
        <li>-</li>
      )}
    </ul>
  );
};
