import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Vertical } from 'globalConstants';
import { RootState } from 'store/rootReducer';
import { editRecordSave } from 'store/actions/editRecordActions';
import { globalModalHide } from 'store/actions/globalModalActions';
import { FORM_FLOWS } from 'connected/FormFlow/constants';
import { FIELD_COMPONENTS, FormFlowConfigs } from 'connected/FormFlow/types';
import { payloadTransformForUpdates } from 'pages/NewLease/NewLeaseForm/payloadTransform';
import { getSummarySelector } from 'store/selectors/detailsSelectors';
import { EDIT_TENANT_COPY } from '../constants';
import EditRecordForm from '../EditRecordForm';
import configs from './editTenant.json';

type EditTenantModalProps = {
  recordId: string;
};

const constructPreloadedState = (tenant: any) => {
  const state: any = {
    identifyTenant: {},
    specifyDataSources: [],
  };

  if (!tenant) return state;

  state.identifyTenant = {
    tenantType: {
      tenantType: {
        componentType: FIELD_COMPONENTS.SELECT,
        value: tenant.isPrivate ? 'private-individual' : 'company',
      },
    },
  };

  if (tenant.isPrivate) {
    state.identifyTenant.tenantIndividual = {
      tenantNationality: {
        componentType: FIELD_COMPONENTS.COUNTRY_SELECT,
        label: 'Tenant Nationality',
        value: tenant.companyNationality,
      },
    };
  }

  state.identifyTenant.tenantCompany = {
    tenant: {
      componentType: FIELD_COMPONENTS.COMPANY_SELECT,
      label: 'Tenant Name',
      summaryLabel: tenant.companyName,
      value: JSON.stringify({
        name: tenant.companyName,
        id: tenant.companyId,
      }),
    },
  };

  return state;
};

const EditTenantModal: React.FC<EditTenantModalProps> = ({ recordId }) => {
  const dispatch = useDispatch();
  const building = useSelector(getSummarySelector);
  const existingTenant = useSelector(
    (state: RootState) => state.details.lease?.tenant,
  );
  const initialFormState = useMemo(() => {
    return { ...constructPreloadedState(existingTenant) };
  }, [existingTenant]);

  const constructFormPayload = (formState: any) => {
    const payload = {
      ...payloadTransformForUpdates(formState),
      leaseId: recordId,
    };

    dispatch(
      editRecordSave({
        payload,
        vertical: Vertical.Lease,
      }),
    );
    dispatch(globalModalHide(FORM_FLOWS.EDIT_TENANT));
  };

  return (
    <EditRecordForm
      recordId={recordId}
      copy={EDIT_TENANT_COPY}
      onSubmission={constructFormPayload}
      formId={FORM_FLOWS.EDIT_TENANT}
      configs={configs as FormFlowConfigs}
      building={building}
      initialFormState={initialFormState}
    />
  );
};

export default EditTenantModal;
