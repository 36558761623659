import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { BuildingRecord, DetailsSummary } from 'pages/Details/types';
import {
  ModalCardWrapper,
  ModalCardHeader,
  ModalCardFixedMain,
} from 'components/ModalCard/ModalCard';
import FormFlow from 'connected/FormFlow/FormFlow';
import { FormFlowConfigs } from 'connected/FormFlow/types';
import { FORM_FLOWS } from 'connected/FormFlow/constants';
import { flattenFormState } from 'connected/FormFlow/services/utils';
import { SIDEBAR_CARD_TYPES } from 'connected/FormFlow/FormSidebar/types';
import { globalModalHide } from 'store/actions/globalModalActions';
import { Vertical } from 'globalConstants';
import { getVerticalSelector } from 'store/selectors/detailsSelectors';
import { RecordCopy } from './constants';
import SidebarCard from './SidebarCards/SidebarCard';

export interface EditModalProps {
  recordId?: string;
  formId: FORM_FLOWS;
  configs: FormFlowConfigs;
  copy: RecordCopy;
  onSubmission: (state: any) => void;
  building?: DetailsSummary;
  existingValues?: any;
  portfolioName?: string;
  initialFormState?: any;
  formTitle?: string;
  sidebarCardType?: SIDEBAR_CARD_TYPES;
  handleAltSubmission?: (state: any) => void;
  modalType?: string;
  // When payload has array value, flatten removes all values. This is a workaround to prevent that.
  // It should be fixed in the future.
  omitFlattenFormState?: boolean;
}

const EditModal: React.FC<EditModalProps> = ({
  formId,
  configs,
  copy,
  onSubmission,
  building,
  existingValues,
  portfolioName,
  initialFormState,
  formTitle,
  sidebarCardType,
  recordId,
  handleAltSubmission,
  omitFlattenFormState,
}: EditModalProps) => {
  const dispatch = useDispatch();
  const isSubmitted = useSelector(
    (state: RootState) => state.newRecord.isSubmitted,
  );
  const isSaving = useSelector((state: RootState) => state.newRecord.isSaving);
  const error = useSelector((state: RootState) => state.newRecord.error);
  const vertical = useSelector(getVerticalSelector);

  const handleCloseModal = () => {
    dispatch(globalModalHide(formId));
  };

  const handleSubmit = (formState: any) => {
    const formStateToSubmit = omitFlattenFormState
      ? formState
      : flattenFormState(formState);
    onSubmission(formStateToSubmit);
  };

  const handleAltSubmit = (formState: any) => {
    const formStateToSubmit = omitFlattenFormState
      ? formState
      : flattenFormState(formState);

    if (handleAltSubmission) {
      handleAltSubmission(formStateToSubmit);
    }
    return false;
  };

  const getCard = () => {
    if (sidebarCardType) {
      return <SidebarCard sidebarCardType={sidebarCardType} />;
    }
    if (building && vertical !== Vertical.PortfolioSale) {
      return <SidebarCard address={building} />;
    }

    if (vertical === Vertical.PortfolioSale && portfolioName) {
      return <SidebarCard name={portfolioName} />;
    }

    if (formTitle) {
      return <SidebarCard name={formTitle} />;
    }

    return null;
  };

  const altSubmissionProps = handleAltSubmission
    ? {
        handleAltSubmit,
      }
    : {};

  return (
    <ModalCardWrapper>
      <ModalCardHeader>
        <h2>{copy.heading}</h2>
      </ModalCardHeader>
      <ModalCardFixedMain>
        <FormFlow
          formId={formId}
          recordId={recordId}
          configs={configs}
          handleClose={handleCloseModal}
          submitDisabled={isSaving || !!error}
          submissionError={error}
          handleSubmit={handleSubmit}
          {...altSubmissionProps}
          isSubmitted={isSubmitted}
          renderCard={getCard}
          existingValues={existingValues}
          initialFormState={initialFormState}
        />
      </ModalCardFixedMain>
    </ModalCardWrapper>
  );
};

export type EditWrapperProps = Pick<
  EditModalProps,
  'recordId' | 'onSubmission' | 'existingValues' | 'modalType'
> & {
  building?: BuildingRecord;
};

export default React.memo(EditModal);
