import { RootState } from 'store/rootReducer';

export const getUserSearchStatisticId = (state: RootState) =>
  state.searchStatistics.userSearchStatisticId;
export const getSearchStatisticsResults = (state: RootState) =>
  state.searchStatistics.results;
export const getSearchStatisticsIsCalculating = (state: RootState) =>
  state.searchStatistics.isCalculating;
export const getSearchStatisticsIsError = (state: RootState) =>
  state.searchStatistics.isError;
export const getSearchStatisticsPage = (state: RootState) =>
  state.searchStatistics.page;
export const getSearchStatisticsPageSize = (state: RootState) =>
  state.searchStatistics.pageSize;
export const getSearchStatisticsIsPanelExpanded = (state: RootState) =>
  state.searchStatistics.isPanelExpanded;
