import { ResultView } from 'connected/SearchResults/constants';
import { CreateHeadlineStatistics } from 'connected/SearchResults/components/HeadlineStatistics/types';
import { SearchStatisticsState } from 'store/reducers/searchStatisticsReducer';

export enum SearchActionTypes {
  FETCH_SEARCH_RESULTS = 'FETCH_SEARCH_RESULTS',
  FETCH_SEARCH_RESULTS_SILENTLY = 'FETCH_SEARCH_RESULTS_SILENTLY',
  FETCH_SEARCH_RESULTS_API_SUCCESS = 'FETCH_SEARCH_RESULTS_API_SUCCESS',
  FETCH_SEARCH_RESULTS_API_FAIL = 'FETCH_SEARCH_RESULTS_API_FAIL',
  TOGGLE_INLINE_EDIT_OPEN = 'TOGGLE_INLINE_EDIT_OPEN',
  FETCH_SEARCH_RESULTS_COUNT = 'FETCH_SEARCH_RESULTS_COUNT',
  FETCH_SEARCH_RESULTS_COUNT_SUCCESS = 'FETCH_SEARCH_RESULTS_COUNT_SUCCESS',
  FETCH_SEARCH_RESULTS_COUNT_FAIL = 'FETCH_SEARCH_RESULTS_COUNT_FAIL',
  FETCH_SEARCH_RESULTS_MAP_PINS = 'FETCH_SEARCH_RESULTS_MAP_PINS',
  FETCH_SEARCH_RESULTS_MAP_PINS_API_SUCCESS = 'FETCH_SEARCH_RESULTS_MAP_PINS_API_SUCCESS',
  SWITCH_RESULT_VIEW = 'SWITCH_RESULT_VIEW',
  RESET_MAP_VIEW_PINS = 'RESET_MAP_VIEW_PINS',
  RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS',

  CREATE_SEARCH_RESULTS_STATISTICS = 'CREATE_SEARCH_RESULTS_STATISTICS',
  CREATE_SEARCH_RESULTS_STATISTICS_API_FAIL = 'CREATE_SEARCH_RESULTS_STATISTICS_API_FAIL',
  CREATE_SEARCH_RESULTS_STATISTICS_API_SUCCESS = 'CREATE_SEARCH_RESULTS_STATISTICS_API_SUCCESS',

  FETCH_SEARCH_RESULTS_STATISTICS = 'FETCH_SEARCH_RESULTS_STATISTICS',
  FETCH_SEARCH_RESULTS_STATISTICS_API_FAIL = 'FETCH_SEARCH_RESULTS_STATISTICS_API_FAIL',
  FETCH_SEARCH_RESULTS_STATISTICS_API_SUCCESS = 'FETCH_SEARCH_RESULTS_STATISTICS_API_SUCCESS',
  FETCH_SEARCH_RESULTS_STATISTICS_POLLING_RETRY = 'FETCH_SEARCH_RESULTS_STATISTICS_POLLING_RETRY',

  SEARCH_STATISTICS_TOGGLE_PANEL = 'SEARCH_STATISTICS_TOGGLE_PANEL',
  SEARCH_STATISTICS_RESET = 'SEARCH_STATISTICS_RESET',
}

export type FetchSearchResultsAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS;
};

export const fetchSearchResults = (): FetchSearchResultsAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS,
});

export type FetchSearchResultsSilentlyAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_SILENTLY;
};

export const fetchSearchResultsSilently = (): FetchSearchResultsSilentlyAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_SILENTLY,
});

export type FetchSearchResultsSuccessAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_API_SUCCESS;
  payload: any;
};

export const fetchSearchResultsSuccess = (
  payload: any,
): FetchSearchResultsSuccessAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_API_SUCCESS,
  payload,
});

export type FetchSearchResultsFailAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_API_FAIL;
  payload: any;
};

export const fetchSearchResultsFail = (
  payload: any,
): FetchSearchResultsFailAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_API_FAIL,
  payload,
});

//
export type FetchSearchResulsCountSuccessAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_COUNT_SUCCESS;
  count: number;
};

export type FetchSearchResultsCountAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_COUNT;
};

export const fetchSearchResultsCount = (): FetchSearchResultsCountAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_COUNT,
});

export const fetchSearchResultsCountSuccess = (
  count: number,
): FetchSearchResulsCountSuccessAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_COUNT_SUCCESS,
  count,
});

export type FetchSearchResulsCountFailAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_COUNT_FAIL;
};

export const fetchSearchResultsCountFail = (): FetchSearchResulsCountFailAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_COUNT_FAIL,
});

// create a statistic request on the API queue
export type CreateSearchResultsStatisticsAction = {
  type: SearchActionTypes.CREATE_SEARCH_RESULTS_STATISTICS;
};

export const createSearchResultsStatistics = (): CreateSearchResultsStatisticsAction => ({
  type: SearchActionTypes.CREATE_SEARCH_RESULTS_STATISTICS,
});

export type CreateSearchResultsStatisticsSuccessAction = {
  type: SearchActionTypes.CREATE_SEARCH_RESULTS_STATISTICS_API_SUCCESS;
  payload: CreateHeadlineStatistics;
};

export const createSearchResultsStatisticsSuccess = (
  payload: CreateHeadlineStatistics,
): CreateSearchResultsStatisticsSuccessAction => ({
  type: SearchActionTypes.CREATE_SEARCH_RESULTS_STATISTICS_API_SUCCESS,
  payload,
});

export type CreateSearchResultsStatisticsFailAction = {
  type: SearchActionTypes.CREATE_SEARCH_RESULTS_STATISTICS_API_FAIL;
};

export const createSearchResultsStatisticsFail = (): CreateSearchResultsStatisticsFailAction => ({
  type: SearchActionTypes.CREATE_SEARCH_RESULTS_STATISTICS_API_FAIL,
});

// FETCH_SEARCH_RESULTS_STATISTICS
export type FetchSearchResultsStatisticsAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS;
};

export const fetchSearchResultsStatistics = (): FetchSearchResultsStatisticsAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS,
});

export type FetchSearchResultsStatisticsSuccessAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS_API_SUCCESS;
  payload: SearchStatisticsState;
};

export const fetchSearchResultsStatisticsSuccess = (
  payload: SearchStatisticsState,
): FetchSearchResultsStatisticsSuccessAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS_API_SUCCESS,
  payload,
});

export type FetchSearchResultsStatisticsFailAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS_API_FAIL;
};

export const fetchSearchResultsStatisticsFail = (): FetchSearchResultsStatisticsFailAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS_API_FAIL,
});

export type FetchSearchResultsStatisticsPollingRetryAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS_POLLING_RETRY;
  payload: SearchStatisticsState;
};

export const fetchSearchResultsStatisticsPollingRetry = (
  payload: SearchStatisticsState,
): FetchSearchResultsStatisticsPollingRetryAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS_POLLING_RETRY,
  payload,
});
// END FETCH_SEARCH_RESULTS_STATISTICS

export type FetchSearchResultsMapPinsAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_MAP_PINS;
  page: number;
  count: number;
};

export const fetchSearchResultsMapPins = (
  page: number,
  count: number,
): FetchSearchResultsMapPinsAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_MAP_PINS,
  page,
  count,
});

export type FetchSearchResultsMapPinsSuccessAction = {
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_MAP_PINS_API_SUCCESS;
  payload: any;
  meta: any;
};

export const fetchSearchResultsMapPinsSuccess = (
  payload: any,
  meta: any,
): FetchSearchResultsMapPinsSuccessAction => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_MAP_PINS_API_SUCCESS,
  payload,
  meta,
});

export type ResetMapViewPinsAction = {
  type: SearchActionTypes.RESET_MAP_VIEW_PINS;
};

export const resetMapViewPins = (): ResetMapViewPinsAction => ({
  type: SearchActionTypes.RESET_MAP_VIEW_PINS,
});

export type ResetSearchResultsAction = {
  type: SearchActionTypes.RESET_SEARCH_RESULTS;
};

export const resetSearchResults = (): ResetSearchResultsAction => ({
  type: SearchActionTypes.RESET_SEARCH_RESULTS,
});

export type SwitchResultViewAction = {
  type: SearchActionTypes.SWITCH_RESULT_VIEW;
  payload: ResultView;
};

export const switchResultView = (
  payload: ResultView,
): SwitchResultViewAction => ({
  type: SearchActionTypes.SWITCH_RESULT_VIEW,
  payload,
});

export type SearchStatisticsTogglePanelAction = {
  type: SearchActionTypes.SEARCH_STATISTICS_TOGGLE_PANEL;
  payload: boolean;
};

export const searchStatisticsTogglePanel = (
  payload: boolean,
): SearchStatisticsTogglePanelAction => ({
  type: SearchActionTypes.SEARCH_STATISTICS_TOGGLE_PANEL,
  payload,
});

export type SearchStatisticsResetAction = {
  type: SearchActionTypes.SEARCH_STATISTICS_RESET;
};

export const searchStatisticsReset = (): SearchStatisticsResetAction => ({
  type: SearchActionTypes.SEARCH_STATISTICS_RESET,
});

export type SearchActions =
  | FetchSearchResultsAction
  | FetchSearchResultsSuccessAction
  | FetchSearchResultsFailAction
  | FetchSearchResultsSilentlyAction
  | FetchSearchResultsCountAction
  | FetchSearchResulsCountSuccessAction
  | FetchSearchResulsCountFailAction
  | FetchSearchResultsMapPinsAction
  | FetchSearchResultsMapPinsSuccessAction
  | ResetMapViewPinsAction
  | ResetSearchResultsAction
  | SwitchResultViewAction
  | FetchSearchResultsStatisticsAction
  | FetchSearchResultsStatisticsSuccessAction
  | FetchSearchResultsStatisticsFailAction
  | CreateSearchResultsStatisticsAction
  | CreateSearchResultsStatisticsSuccessAction
  | CreateSearchResultsStatisticsFailAction
  | FetchSearchResultsStatisticsPollingRetryAction
  | SearchStatisticsTogglePanelAction
  | SearchStatisticsResetAction;
