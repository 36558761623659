import { ReactNode } from 'react';

import { LeaseRecord, SaleRecord } from 'pages/Details/types';
import {
  FileUploadType,
  OnDocumentUploadChangeHandler,
} from 'connected/DocumentUploader/types';
import { UploadedDoc } from 'store/reducers/newRecordReducer';
import { INFORMATIONAL_PANEL_TYPES } from 'pages/NewLease/types';
import { FORM_FLOWS } from './constants';
import { CONFIRM_TYPE } from './ConfirmChanges/types';
import { SIDEBAR_CARD_TYPES } from './FormSidebar/types';

export enum MANDATORY_VIEWS {
  DATA_SOURCES = 'specifyDataSources',
}

export enum FIELD_COMPONENTS {
  LARGE_RADIO = 'LargeRadio',
  RADIO = 'Radio',
  CHECKBOX = 'Checkbox',
  DATE_PICKER = 'DatePicker',
  FILE_INPUT = 'FileInput',
  MULTIPLE_FILE_INPUT = 'MultipleFileInput',
  FIELDSET = 'FieldSet',
  MULTILINE_FIELDSET = 'MultilineFieldset',
  MULTILINE_BATCH = 'MultilineWithBatchAdd',
  MULTILINE_BATCH_ORDERED = 'MultilineWithBatchOrderedAdd',
  MULTILINE_REPEAT = 'MultilineWithRepeat',
  MULTILINE_GROUPED_REPEAT = 'MultilineWithGroupedRepeat',
  MULTILINE_BUYER = 'MultilineBuyer',
  MULTILINE_DEMISE = 'MultiLineDemise',
  MULTILINE_FLOOR_PARTITION = 'MultilineFloorPartition',
  IDENTIFY_BUILDINGS = 'IdentifyBuildings',
  SEARCH_INPUT = 'SearchInput',
  SELECT = 'SelectInput',
  DYNAMIC_SELECT = 'DynamicSelectInput',
  MULTI_SELECT = 'MultiSelect',
  DYNAMIC_MULTI_SELECT = 'DynamicMultiSelect',
  USE_CLAUSE_SELECT = 'UseClauseSelectInput',
  TEXTAREA = 'Textarea',
  TEXT_INPUT = 'TextInput',
  HIDDEN_TEXT_INPUT = 'HiddenTextInput',
  PRICE_INPUT = 'PriceInput',
  DECIMAL_PRICE_INPUT = 'DecimalPriceInput',
  PERCENTAGE = 'PercentageInput',
  COUNTRY_SELECT = 'CountrySelect',
  FLOOR_LEVEL_SELECT = 'FloorLevelSelect',
  FLOOR_SELECT = 'FloorSelect',
  PARTITION_SELECT = 'PartitionSelect',
  NUMERIC_INPUT = 'NumericInput',
  YEAR_INPUT = 'YearInput',
  COMPANY_SELECT = 'CompanySelect',
  SQUARE_FT_INPUT = 'SquareFtInput',
  PRICE_PER_SQ_FT_INPUT = 'PricePerSqFtInput',
  DATE_LENGTH = 'DateLength',
  MONTH_INPUT = 'MonthInput',
  NAVIGATION_CHECKBOX = 'NavigationCheckbox',
  FIND_MATCHES = 'FindMatches',
  COMPANY_FIELDSET = 'CompanyFieldset',
  INTEGER = 'Integer',
  CREATE_FLOOR_PARTITION = 'CreateFloorPartition',
  LARGE_CHECKBOX = 'LargeCheckbox',
  IDENTIFY_ADDRESS = 'IdentifyAddress',
  MOVE_OTHER_LEASES = 'MoveOtherLeases',
  CONFIRM_CHANGES = 'ConfirmChanges',
  RENEW_OTHER_LEASES = 'RenewOtherLeases',
  RELATIVE_DATEPICKER = 'RelativeDatePicker',
  MERGE_RECORDS = 'MergeRecordComparison',
  INTROHIVE_DATA_ITEM = 'IntrohiveDataItem',
  EPC_SELECT = 'EpcSelect',
}

export type FormStateValue = {
  label?: string;
  subSource?: string;
  componentType: FIELD_COMPONENTS;
  summaryLabel: string;
  value: any;
};

export type ConditionalConfig = {
  ref: string;
  value: string | (string | boolean)[];
  inverse?: boolean;
  type?: 'COMPANY';
  field?: string;
  existingRef?: string;
  existingValue?: string | (string | boolean)[];
};

export enum ValidationEntityKey {
  groupId = 'groupId',
  fieldId = 'fieldId',
}

export enum OptionSets {
  SECTOR = 'SECTOR',
  ROLE = 'ROLE',
}

export enum COMPANY_FIELDS {
  NAME = 'COMPANY_NAME',
  SECTOR = 'COMPANY_SECTOR',
  NATIONALITY = 'COMPANY_NATIONALITY',
}

export enum COMPANY_TYPE {
  CH = 'COMPANY_HOUSE',
  DB = 'ATHENA_DB',
  MANUAL = 'MANUAL_ADDED',
}

export type ValidationProps = {
  match?: string;
  message?: string;
  placeholder?: string;
  isRequired?: boolean;
  minValue?: any;
  maxValue?: any;
  dependentOn?: string;
  dependentRowKey?: string;
  minDate?: any;
  maxDate?: any;
};

export type ErrorProps = {
  message: string;
  field: string;
  display?: boolean;
};

export type OptionProps = {
  id: string;
  label: string;
  value: string;
};

export type UpdateRefsProps = {
  targetKey: string;
  sourceKey: any;
};

export type InlineLabelProps = { field: string; label: string; key: string };

export type FieldProps = {
  childFields?: any;
  componentType: FIELD_COMPONENTS;
  fieldId: string;
  label: string;
  name: string;
  options: OptionProps[];
  optionSet?: OptionSets;
  required: boolean;
  conditional?: ConditionalConfig[];
  prompt?: string;
  validation?: ValidationProps;
  readOnly?: boolean;
  prepopulated?: any;
  defaultValue?: any;
  lineLimit?: number;
  addBatchLabel?: string[];
  addBatchButtonLabel?: string;
  addButton?: string;
  addRepeatable?: string;
  repeatableKeys?: string[];
  removeLineLabel?: string;
  multilineTitle?: string;
  resetFormState?: boolean;
  resetRefsOnChange?: string[];
  dependentOn?: string;
  columns?: number;
  jsonKey?: string;
  updateRefsOnChange?: UpdateRefsProps[];
  conditionalOptions?: boolean;
  inlineLabels?: InlineLabelProps[];
  fieldType?: COMPANY_FIELDS;
  subtitle?: string;
  decimals?: number;
  multilineType?: string;
  minimumLines?: number;
};

export type GroupProps = {
  groupId: string;
  componentType: FIELD_COMPONENTS;
  name: string;
  label: string;
  options?: OptionProps[];
  optionSet?: OptionSets;
  title?: string;
  prompt?: string;
  altPrompt?: string;
  subtitle?: string;
  description?: string;
  subGroups?: GroupProps[];
  fields?: FieldProps[];
  sourceLabel?: string;
  addBatchLabel?: string[];
  addBatchButtonLabel?: string;
  addButton?: string;
  addRepeatable?: string;
  repeatableKeys?: string[];
  hideRepeatable?: ConditionalConfig[];
  removeLineLabel?: string;
  multilineTitle?: string;
  columns?: number;
  fileInputType?: FileUploadType;
  validation?: ValidationProps;
  readOnly?: boolean;
  prepopulated?: any;
  defaultValue?: any;
  lineLimit?: number;
  conditional?: ConditionalConfig[];
  conditionalOptions?: boolean;
  addConditional?: boolean;
  resetFormState?: boolean;
  resetRefsOnChange?: string[];
  dependentOn?: string;
  jsonKey?: string;
  updateRefsOnChange?: UpdateRefsProps[];
  additionalStyles?: string[];
  multilineLabel?: string;
  fieldType?: COMPANY_FIELDS;
  multilineType?: string;
  childFields?: any;
  minDate?: string;
  confirmType?: CONFIRM_TYPE;
  isRecordType?: boolean;
  hideNonCanonical?: boolean;
  minimumLines?: number;
  minimumDeleteRows?: number;
};

export type EditableProps = {
  path: string;
  key: string;
  text: string;
};

export type SelectableProps = {
  name: string;
  key: string;
  onSelect?: (val: any) => void;
  updateRefsOnChange?: UpdateRefsProps[];
};

export enum FormFlowTypes {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  SPV = 'SPV',
}

export enum MatchType {
  SALE = 'sales',
  LEASE = 'leases',
}

export type MatchesFilter = {
  key: string;
  subKey?: string;
  value?: string[];
  ref?: string;
  alsoAllow?: string[][];
};

export type FormSummaryItemProps = {
  title: string;
  body: string;
};

export enum ConfigFieldType {
  PRICE = 'PRICE',
  DATE = 'DATE',
  COMPANY = 'COMPANY',
  STRING = 'STRING',
  SQ_FEET = 'SQ_FEET',
  SINGLE_COMPANY = 'SINGLE_COMPANY',
  SELLER = 'SELLER',
  BUYER = 'BUYER',
  FLOOR_PARTITION = 'FLOOR_PARTITION',
}

export type MatchConfigProps = {
  key: string;
  value: string;
  type: ConfigFieldType;
  isWide?: boolean;
  hideIfEmpty?: boolean;
};

export type FindMatchesNoticeProps = {
  noticeText: string;
  conditional: { ref: string; value: string[]; statePath: string };
};

export type FindMatchesPanelProps = {
  returnedMatches: (SaleRecord | LeaseRecord)[];
  matchConfig: MatchConfigProps[];
  isEditable?: EditableProps;
  columnsToSpan: number;
  isSelectable?: SelectableProps;
  onSelect: (
    val: string,
    label: string,
    id: string,
    updateRefs: UpdateRefsProps[] | [],
  ) => void;
  selectedValue?: string;
  label: string;
  sortBy: string | { ref: string; key: string };
  sortByType?: string;
  displayNoMatches?: boolean;
  hasNotice?: FindMatchesNoticeProps;
};

export type MatchProps = {
  componentType: FIELD_COMPONENTS;
  resetRefsOnChange?: string[];
  update?: string[];
  jsonKey?: string;
  resetFormState?: boolean;
  updateRefsOnChange?: UpdateRefsProps[];
};

export type FindMatchesProps = {
  title: string;
  body: string;
  key: string;
  columnsToSpan: number;
  isEditable?: EditableProps;
  isSelectable?: SelectableProps;
  matchType: MatchType;
  label: string;
  returnedMatches: (SaleRecord | LeaseRecord)[];
  matchConfig: MatchConfigProps[];
  filters?: MatchesFilter[];
  resetRefsOnChange?: string[];
  sortBy: string | { ref: string; key: string };
  sortByType?: string;
  displayNoMatches?: boolean;
  bodyWithForm?: string;
};

export type SummaryField = {
  summaryTitle: string;
  path: string;
  summaryFormat?: string;
};

export type EquityProp = {
  value: number;
  label: string;
};

export type PayloadValue<T> = {
  value: T;
  subSource?: string;
};

export type DemiseFloor = {
  floorName: PayloadValue<string>;
  floorId: PayloadValue<string>;
};

export type DemisePartition = {
  partitionName: PayloadValue<string>;
  partitionId: PayloadValue<string>;
};

export type Demise = {
  index: number;
  achievedRent: PayloadValue<number>;
  totalArea: PayloadValue<number>;
  askingRent?: PayloadValue<number>;
  offerRent?: PayloadValue<number>;
  netEffectiveRent?: PayloadValue<number>;
  floor: PayloadValue<string> | DemiseFloor;
  partition: PayloadValue<string> | DemisePartition;
  floorLevel: PayloadValue<number> & { floorId: string };
  floorId: PayloadValue<string>;
  measurementStandard?: PayloadValue<string>;
  use?: PayloadValue<string>;
  partitionId?: PayloadValue<string>;
};

export type FloorPartition = {
  partitionName: string;
  measurementStandard?: string;
  useType?: string;
  areaSqFt?: number;
  isHistoric?: boolean;
};

export type Floor = {
  floorId: string;
  floorName: string;
  partitions: FloorPartition[];
  partitionName?: string;
  floorLevel: number;
  measurementStandard?: string;
  useType?: string;
  areaSqFt?: number;
  canBeRemoved?: boolean;
  isCanonical?: boolean;
  originalFloorLevel?: number;
};

export enum VIEW_TYPES {
  LESSOR_VIEW = 'LESSOR_VIEW',
}

export type ViewConfig = {
  viewId: string;
  title: string;
  hideTitle?: boolean;
  parentSection: string;
  canJumpToFinish?: boolean;
  groups?: GroupProps[];
  conditional?: ConditionalConfig[];
  collectSources?: boolean | ConditionalConfig;
  nextStepButton?: string;
  customFinishStepButton?: string;
  customNextStepButton?: string;
  customNavigation?: ConditionalConfig[];
  submitButtonCopy?: string;
  findMatches?: FindMatchesProps;
  summaryFields?: SummaryField[];
  skipToScreen?: boolean;
  hideNextBtnIfFileExists?: boolean;
  hideIfScreenVisible?: string;
  showErrorsIfNoMatchingRecords?: ErrorMessageConfig[];
  viewType?: VIEW_TYPES;
  informationalPanel?: INFORMATIONAL_PANEL_TYPES;
  hideProgressSteps?: boolean;
  altSubmitButtonCopy?: string;
  list?: {
    title: string;
    items: string[];
  };
  additionalDataSources?: SourceOption[];
};

export type ErrorMessageConfig = {
  msg: string;
  ref?: string;
  subKey?: string;
};

export type RefType = {
  ref: string;
  value: string;
};

export type SourceOption = {
  label: string;
  value: string;
};

export type Sources = {
  [key: string]: SourceOption;
};

export type ProgressSection = {
  id: string;
  title: string;
  views: string[];
};

export type FormFlowConfigs = {
  views: ViewConfig[];
  progressSections: ProgressSection[];
  sourceOptions?: SourceOption[];
  sidebarCardType?: SIDEBAR_CARD_TYPES;
};

export interface FormFlowProps {
  formId: FORM_FLOWS;
  configs: FormFlowConfigs;
  handleClose: () => void;
  handleSubmit: (formState: any) => void;
  handleAltSubmit?: (formState: any) => void;
  renderCard?: () => ReactNode;
  customNavigationHandler?: (
    value: any,
    callback: Function,
    formState: any,
  ) => void;
  handleDocumentUploadChange?: OnDocumentUploadChangeHandler;
  disableNavigationProceed?: boolean;
  disableNavigationBack?: boolean;
  submitDisabled: boolean;
  isSubmitted: boolean;
  submissionError?: string | string[];
  uploadedDoc?: UploadedDoc;
  existingValues?: FormExistingValues[];
  initialFormState?: any;
  recordId?: string;
}

export interface DataSourceRowItem {
  path: string;
  value: any;
  title: string;
  componentType: string;
  subSource: string;
}

export type FormViewConfig = Omit<ViewConfig, 'groups'> & {
  visibleGroups?: GroupProps[];
  validation?: {
    requiresOneOf?: string[];
  };
  hideMultilineTitle?: boolean;
};

export type FieldOnChangeHandler = (
  value: string,
  label: string | undefined,
  key: string,
  rules: FieldProps | GroupProps | MatchProps,
  groupId?: string | null,
  accompanyingData?: { key: string; value: string }[],
) => void;

export interface FormViewProps {
  configs: FormFlowConfigs;
  viewConfig: FormViewConfig;
  formState: any;
  onChange: FieldOnChangeHandler;
  handleDocumentUploadChange?: OnDocumentUploadChangeHandler;
  goDirectToView: (viewId: string) => void;
  onSourceChange: (option: SourceOption, path: string) => void;
  onAllSourcesChange: (option: SourceOption) => void;
  viewErrors: ErrorProps[];
  existingPriorRecords?: FindMatchesProps;
  existingValues?: any;
  recordId?: string;
}

export interface LessorViewProps {
  viewConfig: FormViewConfig;
  formState: any;
  onChange: FieldOnChangeHandler;
  viewErrors: ErrorProps[];
  existingPriorRecords?: FindMatchesProps;
}

export type LineConfigProps = {
  name: string;
  label: string;
  componentType: FIELD_COMPONENTS;
  validation?: ValidationProps;
  options?: OptionProps[];
  optionSet?: OptionSets;
  prepopulated?: any;
  columns?: number;
  resetFormState?: boolean;
  resetRefsOnChange?: string[];
  fieldType?: string;
  isHidden?: boolean;
  hideBlankOption?: false;
  childFields?: any;
};

export type MultilineProps = {
  lineConfig: LineConfigProps[];
  handleOnChange: (state: any) => void;
  addButton?: string;
  formStateItems?: any;
  lineLimit?: number;
  formState?: any;
  id?: string;
  description?: string;
  multilineTitle?: string;
  prompt?: string;
  groupId?: string;
  multilineType?: string;
};

export type MultilineFieldChangeHandler = (
  index: number,
  value: any,
  name: string,
  label: string,
  componentType: string,
  resetRowState?: boolean,
  resetFields?: string[],
  formattedValue?: string,
  id?: string,
) => void;

export type UpdateActiveSectionPayload = {
  index: number;
  name: string;
};

export type FormExistingValues = {
  key: string;
  value?: string | number | boolean | Date;
};
export type InitializeFormFlowPayload = {
  name: string;
};

export type StyledFieldsetFieldsProps = {
  columns?: number;
  additionalStyles?: string[];
};

export type LabelValueFormat = {
  label: string;
  value: any;
};

export type InvestorRow = {
  buyerInvestorOccupier?: LabelValueFormat;
  holdingEntityInvestorOccupier?: LabelValueFormat;
};

export enum FormFlowActionTypes {
  SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION',
  INITIALIZE_FORM_BUILDER = 'INITIALIZE_FORM_BUILDER',
  RESET_FORM_BUILDER = 'RESET_FORM_BUILDER',
  INSTANTIATE_SECTION = 'INSTANTIATE_SECTION',
  GET_PORTFOLIO_MATCHING_RECORDS_SUCCESS = 'GET_PORTFOLIO_MATCHING_RECORDS_SUCCESS',
  GET_PORTFOLIO_MATCHING_RECORDS = 'GET_PORTFOLIO_MATCHING_RECORDS',
}

export type GetPortfolioSalesMatchesAction = {
  type: FormFlowActionTypes.GET_PORTFOLIO_MATCHING_RECORDS;
};

export type GetPortfolioSalesMatchesSuccessAction = {
  type: FormFlowActionTypes.GET_PORTFOLIO_MATCHING_RECORDS_SUCCESS;
  payload: {
    sales: SaleRecord[];
    leases?: LeaseRecord[];
  };
};

export type UpdateActiveSection = {
  type: FormFlowActionTypes.SET_ACTIVE_SECTION;
  payload: UpdateActiveSectionPayload;
};

export type InitializeFormFlow = {
  type: FormFlowActionTypes.INITIALIZE_FORM_BUILDER;
  payload: InitializeFormFlowPayload;
};

export type InstantiateSection = {
  type: FormFlowActionTypes.INSTANTIATE_SECTION;
};

export type ResetFormFlow = {
  type: FormFlowActionTypes.RESET_FORM_BUILDER;
};

export type FormFlowActions =
  | UpdateActiveSection
  | InitializeFormFlow
  | InstantiateSection
  | GetPortfolioSalesMatchesAction
  | GetPortfolioSalesMatchesSuccessAction
  | ResetFormFlow;
