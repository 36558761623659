import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'ramda/src/isEmpty';
import { v4 as uuid } from 'uuid';

import { Vertical } from 'globalConstants';
import { getEditSellers } from 'store/selectors/buyersSellersSelectors';
import { editRecordSave } from 'store/actions/editRecordActions';
import { globalModalHide } from 'store/actions/globalModalActions';
import { FORM_FLOWS } from 'connected/FormFlow/constants';
import { FormFlowConfigs } from 'connected/FormFlow/types';
import { payloadTransformForUpdates } from 'pages/NewSale/NewSaleForm/payloadTransform';
import { getSummarySelector } from 'store/selectors/detailsSelectors';
import { EDIT_SELLERS_COPY } from '../constants';
import EditRecordForm from '../EditRecordForm';
import configs from './editSellers.json';

type EditSellersModalProps = {
  recordId: string;
};

const preloadedFormState: any = {
  identifySellers: {
    sellerDetails: {
      componentType: 'MultilineWithGroupedRepeat',
    },
  },
  specifyDataSources: [],
};

const EditSellersModal: React.FC<EditSellersModalProps> = ({ recordId }) => {
  const dispatch = useDispatch();
  const building = useSelector(getSummarySelector);
  const existingSellers = useSelector(getEditSellers);
  const initialFormState = useMemo(() => {
    const updatedState = { ...preloadedFormState };

    updatedState.identifySellers.sellerDetails.value = isEmpty(existingSellers)
      ? [{ index: 0, id: uuid() }]
      : existingSellers;

    return updatedState;
  }, [existingSellers]);

  const constructFormPayload = (formState: any) => {
    const payload = {
      ...payloadTransformForUpdates(formState),
      saleId: recordId,
    };

    dispatch(
      editRecordSave({
        payload,
        vertical: Vertical.Sale,
      }),
    );
    dispatch(globalModalHide(FORM_FLOWS.EDIT_SELLERS));
  };

  return (
    <EditRecordForm
      recordId={recordId}
      copy={EDIT_SELLERS_COPY}
      onSubmission={constructFormPayload}
      formId={FORM_FLOWS.EDIT_SELLERS}
      configs={configs as FormFlowConfigs}
      initialFormState={initialFormState}
      building={building}
    />
  );
};

export default EditSellersModal;
