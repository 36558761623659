import styled, { css } from 'styled-components/macro';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { hideVisually } from 'polished';
import {
  labelStyles,
  minimizedLabelStyles,
  inputWrapperStyles,
  zIndex,
  inputWrapperDisabledStyles,
} from 'styled/Global';
import { getHiddenStyles, getMinimizedStyles } from 'styled/helpers';

export const StyledSearchIcon = styled(Search)`
  fill: ${(props) => props.theme.colorPalette.secondary.midGrey};
`;

const commonSelectStyled = css`
  font-size: ${(props) => props.theme.typography.paragraph.xsmall};
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colorPalette.branding.ink};
  min-width: 120px;

  &.react-select--is-disabled,
  .react-select__control--is-disabled {
    background: none;
    color: ${(props) => props.theme.colorPalette.secondary.midGrey};

    .react-select__indicator {
      color: ${(props) => props.theme.colorPalette.secondary.midGrey};
    }
  }

  .react-select__multi-value {
    position: relative;
    top: 3px;
    margin: 5px 5px 0 0;

    .react-select__multi-value__label {
      font-size: ${(props) => props.theme.typography.paragraph.small};
      padding: 2px;
    }

    &:first-child {
      margin-top: 30px;
    }
  }

  .react-select__multi-value--is-disabled {
    background: none;

    .react-select__multi-value__remove {
      display: none;
    }

    span {
      font-weight: 400;
    }
  }

  .react-select__dropdown-indicator {
    position: absolute;
    right: -16px;
    color: ${(props) => props.theme.colorPalette.secondary.midGrey};
  }

  .react-select__clear-indicator {
    position: absolute;
    right: -15px;
    z-index: 9999;
    cursor: pointer;

    & + span + .react-select__dropdown-indicator {
      display: none;
    }
  }
  &:focus {
    outline: 0;
  }

  .react-select__control {
    border: 0;
    box-shadow: none;
  }

  mark {
    font-weight: bold;
    background: none;
  }

  .react-select__placeholder,
  .react-select__input {
    padding-top: 6px;
    padding-bottom: 6px;

    input {
      padding: 4px 0 0;
      top: 2px;
      margin-left: -2px;
      position: relative;
    }
  }

  .react-select__group-heading {
    color: ${(props) => props.theme.colorPalette.secondary.midGrey};
    font-weight: bold;
    font-size: ${(props) => props.theme.typography.paragraph.xsmall};
    padding: 10px 5px;
  }

  .react-select__menu {
    position: absolute;
    border-radius: 0 4px 4px 4px;
    margin: 0;
    box-shadow: none;
    left: 0;
    width: calc(100% + 1px);
    border: 1px solid ${(props) => props.theme.colorPalette.branding.ink};
  }

  .react-select__group {
    margin: 0 0 15px;
  }

  .react-select__group,
  .react-select__menu-list {
    padding: 0;
    border-radius: 0 4px 4px 4px;
  }

  .react-select__menu-list {
    max-height: 300px;
  }

  .react-select__option {
    padding: '10px 5px';
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &--is-selected {
      background: ${(props) => props.theme.colorPalette.secondary.steel};
    }

    &--is-focused,
    &:focus,
    &:active,
    &:hover {
      color: ${(props) => props.theme.colorPalette.branding.ink};
      background: ${(props) => props.theme.colorPalette.secondary.lightGrey};
      outline: 0;
    }
  }

  .react-select__value-container {
    padding: 0;
    font-size: ${(props) => props.theme.typography.paragraph.small};
    display: flex;
    flex-wrap: nowrap;
    &:focus {
      outline: 0;
    }

    &--is-multi {
      flex-wrap: wrap;
      margin: 0;
      justify-content: flex-start;
      flex-direction: row;
      align-items: baseline;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__single-value {
    margin: 4px 0;
    > span {
      font-weight: 400;
    }
  }

  .react-select__menu-notice {
    padding: ${(props) => props.theme.spacing.default};
  }
`;

const getCreatableWideStyles = (breakpoint: any, isWide?: boolean) => {
  if (isWide) {
    return css`
      .react-select__menu {
        min-width: 100%;
        @media (min-width: ${breakpoint}px) {
          width: 100%;
          top: auto;
          margin-top: 3px;
        }
      }
    `;
  }

  return ``;
};

export const StyledSelect = styled(Select)<{
  isWide: boolean;
  isDisabled: boolean;
}>`
  ${commonSelectStyled}
  ${(props) =>
    getCreatableWideStyles(props.theme.breakpoints.large, props.isWide)}
`;

export const CreateNewStyled = styled.div`
  border: 1px dashed ${(props) => props.theme.colorPalette.branding.ink};
  padding: 12px;
`;

const getWideMenuStyles = (isWide?: boolean) => {
  if (isWide) {
    return css`
      position: static;

      .react-select__menu {
        top: auto;
      }
    `;
  }

  return ``;
};

const getErrorStyles = (color: string, hasErrors?: boolean) => {
  if (hasErrors) {
    return css`
      border: 1px solid ${color};
    `;
  }

  return ``;
};

const getDisabledStyles = (isDisabled?: boolean) => {
  if (isDisabled) {
    return css`
      ${inputWrapperDisabledStyles}

      input {
        background: none;
      }
    `;
  }

  return ``;
};

const getMultiStyles = (isMulti?: boolean) => {
  if (isMulti) {
    return css`
      .react-select__multi-value {
        top: 0;
      }
    `;
  }

  return ``;
};

export const StyledCreatableSelect = styled(CreatableSelect)<{
  isWide: boolean;
}>`
  ${commonSelectStyled}
  ${(props) =>
    getCreatableWideStyles(props.theme.breakpoints.large, props.isWide)}
`;

export const StyledSelectWrapper = styled.div<{
  isWide: boolean;
  isDisabled: boolean;
  isMulti?: boolean;
  hasErrors?: boolean;
}>`
  ${inputWrapperStyles}
  height: auto;
  min-height: 48px;

  &:focus {
    border: 1px solid ${(props) => props.theme.colorPalette.branding.ink};
    label {
      ${minimizedLabelStyles}
    }

    .react-select__placeholder {
      ${hideVisually()}
    }
  }
  &:focus-within {
    outline: 0;
    border: 1px solid ${(props) => props.theme.colorPalette.branding.ink};

    .react-select__indicator,
    .react-select__dropdown-indicator {
      color: ${(props) => props.theme.colorPalette.secondary.midGrey};
    }
  }

  ${(props) => getWideMenuStyles(props.isWide)}
  ${(props) => getDisabledStyles(props.isDisabled)}
  ${(props) => getDisabledStyles(props.isDisabled)}
  ${(props) => getMultiStyles(props.isMulti)}
  ${(props) =>
    getErrorStyles(props.theme.colorPalette.secondary.red, props.hasErrors)}
`;

type LabelType = {
  labelIsHidden?: boolean;
  isWide?: boolean;
  labelIsMinimized: boolean;
  labelZIndex?: number;
  isMulti?: boolean;
};

const getLabelMultiStyles = (isMulti?: boolean) => {
  if (isMulti) {
    return css`
      top: 50%;
      transform: translateY(-50%);
    `;
  }

  return ``;
};

const getMultiLabelMinimizedStyles = (isMultiMinimized?: boolean) => {
  if (isMultiMinimized) {
    return css`
      top: 18px;
    `;
  }
  return ``;
};

export const StyledLabel = styled.label<LabelType>`
  z-index: ${(props) => props.labelZIndex || zIndex.reactSelectLabel};
  ${(props) => getHiddenStyles(props.labelIsHidden)};
  ${(props) => getMinimizedStyles(props.labelIsMinimized)};
  ${labelStyles}

  ${(props) => getLabelMultiStyles(props.isMulti)}


  ${(props) =>
    getMultiLabelMinimizedStyles(props.labelIsMinimized && props.isMulti)}

  pointer-events: none;
`;

export const OptionTitle = styled.span`
  font-weight: bold;

  .companyNumber {
    display: inline-block;
    font-weight: normal;
    padding-left: 4px;
  }

  + span {
    span {
      text-align: right;
      display: inline-block;
      max-width: 340px;
      @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
        max-width: 600px;
      }
    }
  }
`;

export const ShowMoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ShowMoreButtonStyled = styled.button`
  cursor: pointer;
  padding: 4px 12px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;
