import {
  EpcSelectActionTypes,
  EpcSelectApiFailAction,
  EpcSelectApiSuccessAction,
  EpcSelectQueryAction,
  EpcSelectResetAction,
  EpcSelectResult,
} from 'connected/EpcSelect/types';
import { GlobalApiFailError } from './types';

export const epcSelectQuery = (
  searchTerm: string,
  page = 0,
): EpcSelectQueryAction => ({
  type: EpcSelectActionTypes.EPC_SELECT_QUERY,
  payload: { searchTerm, page },
});

export const epcSelectApiSuccess = (
  payload: EpcSelectResult,
): EpcSelectApiSuccessAction => ({
  type: EpcSelectActionTypes.EPC_SELECT_API_SUCCESS,
  payload,
});

export const epcSelectApiFail = (
  payload: GlobalApiFailError,
): EpcSelectApiFailAction => ({
  type: EpcSelectActionTypes.EPC_SELECT_API_FAIL,
  payload,
});

export const epcSelectReset = (): EpcSelectResetAction => ({
  type: EpcSelectActionTypes.EPC_SELECT_RESET,
});
