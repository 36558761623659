import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { globalContent } from 'globalConstants';

import PanelWrapper from 'components/PanelWrapper/PanelWrapper';
import {
  getTelemetryStatisticsResults,
  getTelemetryStatisticsResultsFetching,
} from 'store/selectors/telemetryStatisticsSelectors';
import { getTelemetryStatisticsAction } from 'store/actions/telemetryStatisticsActions';
import { LoadingSpinner } from 'components/Spinner/Spinner';
import { ADMIN_STATISTICS_PAGE_COPY } from './constants';
import StatisticsPanelTable from './StatisticsPanelTable';

const AdminStatistics: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTelemetryStatisticsAction());
  }, [dispatch]);

  const telemetryStatisticsIsFetching = useSelector(
    getTelemetryStatisticsResultsFetching,
  );
  const telemetryStatisticsResults = useSelector(getTelemetryStatisticsResults);

  return (
    <PanelWrapper
      title={ADMIN_STATISTICS_PAGE_COPY.title}
      id="usersListPanel"
      anchorId="usersList"
    >
      {telemetryStatisticsIsFetching ? (
        <>
          <LoadingSpinner /> {globalContent.loading}
        </>
      ) : (
        <StatisticsPanelTable statistics={telemetryStatisticsResults} />
      )}
    </PanelWrapper>
  );
};

export default AdminStatistics;
