import { Vertical, urls } from 'globalConstants';
import React from 'react';
import { useDispatch } from 'react-redux';
import { quickSearchItemClicked } from 'store/actions/quickSearchActions';
import { QuickSearchResult, QuickSearchOnResultItemClick } from '../types';
import { ResultItemButton, ResultItemLink } from './ResultItemStyled';

interface ResultItemCtaProps {
  vertical: Vertical;
  entityId: string;
  entity: QuickSearchResult;
  onResultItemClick?: QuickSearchOnResultItemClick;
  children: React.ReactNode;
  isChild?: boolean;
  isDisabled?: boolean;
}

const ResultItemCta: React.FC<ResultItemCtaProps> = ({
  children,
  onResultItemClick,
  vertical,
  entityId,
  entity,
  isChild,
  isDisabled,
}: ResultItemCtaProps) => {
  const dispatch = useDispatch();

  const onLinkClick = () => {
    dispatch(quickSearchItemClicked({ vertical, entityId }));
  };

  return onResultItemClick ? (
    <ResultItemButton
      type="button"
      onClick={() =>
        onResultItemClick({
          id: entityId,
          entity,
          vertical,
        })
      }
      disabled={isDisabled}
      isChild={isChild}
    >
      {children}
    </ResultItemButton>
  ) : (
    <ResultItemLink
      to={urls.searchDetails(vertical, entityId)}
      onClick={onLinkClick}
    >
      {children}
    </ResultItemLink>
  );
};

export default ResultItemCta;
