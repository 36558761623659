import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import {
  FullScreenImageActionTypes,
  fetchFullScreenImageSignedUrlSuccess,
  fetchFullScreenImageSignedUrlFail,
} from 'store/actions/fullScreenImageActions';

type DocumentDownloadEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const fullScreenImageEpic: Epic = (
  action$,
  state$,
  { authRequest }: DocumentDownloadEpicDependencies,
) => {
  return action$.pipe(
    ofType(FullScreenImageActionTypes.FETCH_FULLSCREEN_IMAGE_SIGNED_URL),
    switchMap((action) => {
      const { entityId, filename } = action.payload;
      const url = endpoints.document.download(entityId, filename);
      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((results) => {
          if (results.status !== 200) {
            throw new Error(`error loading ${url}`);
          }

          return fetchFullScreenImageSignedUrlSuccess(
            results.response?.signedUrl,
          );
        }),
      );
    }),
    catchError((error) => {
      return of(fetchFullScreenImageSignedUrlFail(error));
    }),
  );
};

export default fullScreenImageEpic;
