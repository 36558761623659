import {
  AddEpcAction,
  AddEpcActionTypes,
  AddEpcFailAction,
  AddEpcSuccessAction,
} from 'connected/EpcCertificatesPanel/AddEpc/types';
import { GlobalApiFailError } from './types';

export const addEpc = (epcIds: string[], buildingId: string): AddEpcAction => ({
  type: AddEpcActionTypes.ADD_EPC,
  payload: { epcIds, buildingId },
});

export const addEpcSuccess = (): AddEpcSuccessAction => ({
  type: AddEpcActionTypes.ADD_EPC_SUCCESS,
});

export const addEpcFail = (payload: GlobalApiFailError): AddEpcFailAction => ({
  type: AddEpcActionTypes.ADD_EPC_FAIL,
  payload,
});
