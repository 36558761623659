import { Company } from 'pages/Details/types';
import React from 'react';
import { SidebarTitle, SidebarBody } from './SidebarCardsStyled';

interface CompanySectionProps {
  title: string;
  companies?: Company[];
}

const CompanySection: React.FC<CompanySectionProps> = ({
  title,
  companies,
}: CompanySectionProps) => {
  if (!companies || companies.length === 0) {
    return <></>;
  }
  return (
    <>
      <SidebarTitle>{title}</SidebarTitle>
      <SidebarBody>
        {companies?.reduce((accum: string, company: Company, i: number) => {
          if (i === companies.length - 1) {
            return `${accum}${company.companyName}`;
          }

          return `${accum}${company.companyName}, `;
        }, '')}
      </SidebarBody>
    </>
  );
};

export default CompanySection;
