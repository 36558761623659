import { SectionProps, FilterCategories } from 'components/Filter/types';
import useClauseOptions from 'components/Forms/Inputs/UseClauseSelect/useClauses.json';

export const LEASE_PANEL_COPY = {
  unknown: 'Unknown',
  breakLabel: 'Add Break',
  rentLabel: 'Add Review',
  tooltip:
    'This lease has been hidden by an administrator - check the Notes for details',
};

export const UNKNOWN_FLOOR_NAME = 'UNKNOWN';

export const ANCHOR_LINKS = {
  break: 'lease-break-options',
  rent: 'lease-rent-reviews',
};

export const FILTER_OPTIONS: SectionProps[] = [
  {
    sectionLabel: 'By Lease Status',
    sectionType: FilterCategories.LEASE_STATUS_CATEGORY,
    sectionFilters: [
      {
        label: 'Under Offer',
        value: 'under-offer',
      },
      {
        label: 'Let',
        value: 'let',
      },
      {
        label: 'Terminated',
        value: 'terminated',
      },
    ],
  },
  {
    sectionLabel: 'By Lease Dates',
    sectionType: FilterCategories.LEASE_DATE_CATEGORY,
    sectionFilters: [
      {
        label: 'Expired',
        value: 'expired',
      },
      {
        label: 'Current',
        value: 'current',
      },
      {
        label: 'Future',
        value: 'future',
      },
    ],
  },
  {
    sectionLabel: 'By Disputed Status',
    sectionType: FilterCategories.LEASE_DISPUTED_CATEGORY,
    sectionFilters: [
      {
        label: 'Disputed',
        value: 'disputed',
      },
      {
        label: 'Not Disputed',
        value: 'not-disputed',
      },
    ],
  },
  {
    sectionLabel: 'By Use Clause',
    sectionType: FilterCategories.LEASE_USE_CLAUSE_CATEGORY,
    sectionFilters: useClauseOptions,
  },
  {
    sectionLabel: 'By Visibility',
    sectionType: FilterCategories.RECORD_VISIBILITY,
    sectionFilters: [
      {
        label: 'Visible',
        value: 'visible',
      },
      {
        label: 'Hidden',
        value: 'hidden',
      },
    ],
  },
];
