export type NewRecordCopy = {
  pageTitle: string;
  searchPromptTitle: string;
  addNewRecordButtonLabel: string;
};
export enum NewRecordActionTypes {
  NEW_RECORD_RESET = 'NEW_RECORD_RESET',
}

export type NewRecordResetAction = {
  type: NewRecordActionTypes.NEW_RECORD_RESET;
};

export type NewRecordActions = NewRecordResetAction;

export enum ButtonTypes {
  DEFAULT = 'default',
  DROPDOWN = 'dropdown',
  INLINE = 'inline',
}

export type FormValue = {
  value: string;
  subSource?: string;
};
