import React from 'react';
import { SELECT_SEARCH_INPUT_COPY } from './constants';
import {
  ShowMoreButtonContainer,
  ShowMoreButtonStyled,
} from './SelectSearchStyled';

interface SelectSearchShowMoreButtonProps {
  onClick(): void;
}

const SelectSearchShowMoreButton: React.FC<SelectSearchShowMoreButtonProps> = ({
  onClick,
}: SelectSearchShowMoreButtonProps) => {
  return (
    <ShowMoreButtonContainer>
      <ShowMoreButtonStyled onClick={onClick}>
        {SELECT_SEARCH_INPUT_COPY.showMore}
      </ShowMoreButtonStyled>
    </ShowMoreButtonContainer>
  );
};

export default SelectSearchShowMoreButton;
