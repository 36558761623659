import React, { ReactNode, useContext, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { ThemeContext } from 'styled-components/macro';

import { InfoToolTip, InfoIcon } from './InfoPillStyled';

import { QUICK_SEARCH_COPY } from '../constants';

const paletteMap = {
  alias: 'datavizGreen' as 'datavizGreen',
  formerName: 'datavizTeal' as 'datavizTeal',
  alternativeAddress: 'datavizOrange' as 'datavizOrange',
};

export interface InfoPillProps {
  text: ReactNode;
  type: keyof typeof paletteMap;
  id: string;
  searchType: 'building' | 'company';
}

const InfoPill: React.FC<InfoPillProps> = ({
  text,
  type,
  id,
  searchType,
}: InfoPillProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const copy = QUICK_SEARCH_COPY[type];
  const dataVizColor = paletteMap[type];
  const themeContext = useContext(ThemeContext);
  const pillColor = themeContext.colorPalette.tertiary[dataVizColor];

  const handleTooltipFocus = () => {
    // react-tooltip lib has incorrect type definitions
    const target = tooltipRef.current;

    if (target) {
      ReactTooltip.show(target);
    }
  };

  const handleTooltipBlur = () => ReactTooltip.hide();

  return (
    <>
      <InfoToolTip
        data-tip
        data-for={`info-tooltip-${id}`}
        tabIndex={0}
        data-background-color={pillColor}
        color={pillColor}
        onFocus={handleTooltipFocus}
        onBlur={handleTooltipBlur}
        ref={tooltipRef}
        role="tooltip"
      >
        {copy}
        <InfoIcon />
      </InfoToolTip>
      <ReactTooltip
        id={`info-tooltip-${id}`}
        clickable
        eventOff="mouseleave mouseout scroll mousewheel blur touchmove"
      >
        {QUICK_SEARCH_COPY.tooltip[type]} {searchType}: <br />
        {text}
      </ReactTooltip>
    </>
  );
};

export default InfoPill;
