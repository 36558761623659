import React, { useState, useEffect, useRef, useCallback } from 'react';

import { AccessibleText } from 'styled/Global';
import { KEYBOARD_EVENT_VALUES } from 'globalConstants';

import {
  PanelWrapperStyled,
  PanelMenuCollapseButtonStyled,
  PanelMenuCollapseIcon,
  PanelMenuExpandIcon,
  PanelMenuStyled,
  PanelMenuTitleStyled,
  PanelMenuOptionsStyled,
  PanelBodyStyled,
} from './PanelWrapperStyled';
import { PANEL_COPY } from './constants';
import { PanelTheme, PanelWrapperProps } from './types';

const NoMenu: React.FC = () => <></>;

const PanelWrapper: React.FC<PanelWrapperProps> = ({
  Menu = NoMenu,
  title,
  children,
  useTheme = PanelTheme.DEFAULT,
  isPanelCollapsible = true,
  id,
  anchorId,
  onPanelToggleCb,
  containsMultiChildren,
  count,
  isExpanded = true,
}: PanelWrapperProps) => {
  const [isPanelExpanded, togglePanelExpanded] = useState(isExpanded);
  const initialRenderPhaseRef = useRef(true);

  const handleTogglePanel = () => {
    togglePanelExpanded(!isPanelExpanded);
  };

  const handleKeyboardToggle = useCallback(
    (event) => {
      const keyCodes = [
        KEYBOARD_EVENT_VALUES.ENTER,
        KEYBOARD_EVENT_VALUES.SPACE,
      ];
      if (keyCodes.includes(event.keyCode)) {
        const focusedElement =
          (document.activeElement && document.activeElement.id) || '';
        if (id === focusedElement) {
          togglePanelExpanded((prevIsPanelExpanded) => !prevIsPanelExpanded);
        }
      }
    },
    [id],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardToggle);
    return () => {
      window.removeEventListener('keydown', handleKeyboardToggle);
    };
  }, [handleKeyboardToggle]);

  useEffect(() => {
    if (initialRenderPhaseRef.current) {
      initialRenderPhaseRef.current = false;
      return;
    }

    if (onPanelToggleCb && anchorId) onPanelToggleCb(anchorId, isPanelExpanded);
  }, [isPanelExpanded, anchorId, onPanelToggleCb]);

  const resourceCount = count && count > 0 ? `(${count})` : '';
  return (
    <PanelWrapperStyled id={anchorId} useTheme={useTheme}>
      <PanelMenuStyled
        id={id}
        tabIndex={0}
        aria-expanded={isPanelExpanded}
        aria-controls={`section${id}`}
        role="button"
        containsMultiChildren={containsMultiChildren}
      >
        <PanelMenuTitleStyled>
          {title} {resourceCount}
        </PanelMenuTitleStyled>
        <PanelMenuOptionsStyled>
          <Menu />
        </PanelMenuOptionsStyled>
        {isPanelCollapsible && (
          <PanelMenuCollapseButtonStyled
            onClick={handleTogglePanel}
            type="button"
            tabIndex={-1}
          >
            {isPanelExpanded ? (
              <>
                <AccessibleText>{PANEL_COPY.buttons.collapse}</AccessibleText>
                <PanelMenuCollapseIcon />
              </>
            ) : (
              <>
                <AccessibleText>{PANEL_COPY.buttons.expand}</AccessibleText>
                <PanelMenuExpandIcon />
              </>
            )}
          </PanelMenuCollapseButtonStyled>
        )}
      </PanelMenuStyled>
      {isPanelExpanded && (
        <PanelBodyStyled
          role="region"
          id={`section${id}`}
          hidden={!isPanelExpanded}
        >
          {children}
        </PanelBodyStyled>
      )}
    </PanelWrapperStyled>
  );
};

export default React.memo(PanelWrapper);
