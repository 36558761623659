import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  children: React.ReactNode;
}

const AppTitle: React.FC<Props> = ({ children }: Props) => (
  <Helmet>
    <title>Athena: {children}</title>
  </Helmet>
);

export default AppTitle;
