import React from 'react';
import {
  PageLayout,
  PageHeader,
  PageMain,
} from 'components/Layouts/PageLayout/PageLayoutStyled';

import AppTitle from 'components/AppTitle/AppTitle';
import AdminQueryPanel from 'connected/AdminQueryPanel/AdminQueryPanel';
import AdminStatistics from 'connected/AdminStatistics/AdminStatistics';
import AdminUsers from 'connected/AdminUsers/AdminUsers';
import { ADMIN_CONTAINER_COPY } from './constants';
import { AdminContainerStyled } from './Admin.styles';

export const Admin: React.FC = () => (
  <PageLayout>
    <AppTitle>{ADMIN_CONTAINER_COPY.title}</AppTitle>
    <PageHeader>
      <h1>{ADMIN_CONTAINER_COPY.title}</h1>
    </PageHeader>
    <PageMain>
      <AdminContainerStyled>
        <AdminStatistics />
        <AdminUsers />
        <AdminQueryPanel />
      </AdminContainerStyled>
    </PageMain>
  </PageLayout>
);
