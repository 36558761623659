import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Vertical } from 'globalConstants';
import { RootState } from 'store/rootReducer';
import { editRecordSave } from 'store/actions/editRecordActions';
import { globalModalHide } from 'store/actions/globalModalActions';
import { FORM_FLOWS, STATE_FIELDS } from 'connected/FormFlow/constants';
import { FIELD_COMPONENTS, FormFlowConfigs } from 'connected/FormFlow/types';
import { payloadTransformForUpdates } from 'pages/NewLease/NewLeaseForm/payloadTransform';
import { getSummarySelector } from 'store/selectors/detailsSelectors';
import { EDIT_LANDLORD_COPY } from '../constants';
import EditRecordForm from '../EditRecordForm';
import configs from './editLandlord.json';

type EditLandlordModalProps = {
  recordId: string;
};

const constuctPreloadedFormState = (landlord: any) => {
  const state: any = {
    identifyLandlord: {},
    specifyDataSources: [],
  };

  if (!landlord) {
    return state;
  }

  state.identifyLandlord = {
    landlordType: {
      landlordType: {
        componentType: FIELD_COMPONENTS.SELECT,
        value: landlord.isPrivate ? STATE_FIELDS.PRIVATE_INDIVIDUAL : 'company',
      },
    },
  };

  if (landlord.isPrivate) {
    state.identifyLandlord.landlordIndividual = {
      landlordNationality: {
        componentType: FIELD_COMPONENTS.COUNTRY_SELECT,
        label: 'Landlord Nationality',
        value: landlord.companyNationality,
      },
    };
  }

  state.identifyLandlord.landlordCompany = {
    landlord: {
      componentType: FIELD_COMPONENTS.COMPANY_SELECT,
      label: 'Landlord Name',
      summaryLabel: landlord.companyName,
      value: JSON.stringify({
        name: landlord.companyName,
        id: landlord.companyId,
      }),
    },
  };

  return state;
};

const EditLandlordModal: React.FC<EditLandlordModalProps> = ({ recordId }) => {
  const dispatch = useDispatch();
  const building = useSelector(getSummarySelector);
  const existingLandlord = useSelector(
    (state: RootState) => state.details.lease?.landlord,
  );
  const initialFormState = useMemo(() => {
    return { ...constuctPreloadedFormState(existingLandlord) };
  }, [existingLandlord]);

  const constructFormPayload = (formState: any) => {
    const payload = {
      ...payloadTransformForUpdates(formState),
      leaseId: recordId,
    };

    dispatch(
      editRecordSave({
        payload,
        vertical: Vertical.Lease,
      }),
    );
    dispatch(globalModalHide(FORM_FLOWS.EDIT_LANDLORD));
  };

  return (
    <EditRecordForm
      recordId={recordId}
      copy={EDIT_LANDLORD_COPY}
      onSubmission={constructFormPayload}
      formId={FORM_FLOWS.EDIT_LANDLORD}
      configs={configs as FormFlowConfigs}
      building={building}
      initialFormState={initialFormState}
    />
  );
};

export default EditLandlordModal;
