import styled from 'styled-components/macro';
import { saturate } from 'polished';

const getBackgroundGradient = (color: string) => {
  return `
linear-gradient(
  to right bottom,
  ${saturate('0.2', color)},
  ${color}
)
`;
};

export const ModalCardHeader = styled.header`
  background: ${(props) =>
    getBackgroundGradient(props.theme.colorPalette.branding.yellow)};
  font-size: ${(props) => props.theme.typography.heading.xsmall};
  font-family: ${(props) => props.theme.fonts.primary};
  color: ${(props) => props.theme.colorPalette.text.dark};
  padding: ${(props) => props.theme.spacing.default};
  border-radius: 0;
  position: relative;

  h1,
  h2,
  h3 {
    margin: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    border-radius: 8px 8px 0 0;
  }
`;

export const ModalCardMain = styled.div`
  background: ${(props) => props.theme.colorPalette.branding.white};
  border-radius: 0 0 8px 8px;

  max-height: calc(100vh - 70px);

  @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
    max-height: calc(100vh - 120px);
  }
  overflow: auto;
`;

export const ModalCardFixedMain = styled(ModalCardMain)`
  height: calc(100% - 70px);
`;

export const ModalCardMainOverflowVisible = styled(ModalCardMain)`
  overflow: visible;
`;

export const ModalCardWrapper = styled.div`
  border-radius: 8px;
  height: 100%;
`;

export const GeneralModalTitle = styled.h2`
  padding-left: 36px;
  font-size: ${(props) => props.theme.typography.heading.xsmall};
`;
