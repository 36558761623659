import { of } from 'rxjs';
import { concatMap, catchError, mergeMap } from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { AuthenticatedRequestObservable } from 'apis/request';
import { endpoints } from 'globalConstants';
import { detailsRefresh } from 'store/actions/detailsActions';
import {
  PrimaryImageActionTypes,
  setPrimaryImageSuccess,
  setPrimaryImageFail,
} from 'store/actions/primaryImageActions';
import { setFullScreenImageAsPrimary } from 'store/actions/fullScreenImageActions';

type DocumentDownloadEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const primaryImageEpic: Epic = (
  action$,
  state$,
  { authRequest }: DocumentDownloadEpicDependencies,
) => {
  return action$.pipe(
    ofType(PrimaryImageActionTypes.SET_PRIMARY_IMAGE),
    mergeMap((action) => {
      const { entityId, filename } = action.payload;
      const url = endpoints.document.primaryImage;

      return authRequest(state$, {
        method: 'PUT',
        url,
        body: JSON.stringify({
          entityId,
          filename,
        }),
      })().pipe(
        concatMap((results) => {
          if (results.status !== 200) {
            throw new Error(`PUT ${url} failed`);
          }

          return of(
            setPrimaryImageSuccess(),
            setFullScreenImageAsPrimary(),
            detailsRefresh(),
          );
        }),
      );
    }),
    catchError((error) => {
      return of(setPrimaryImageFail(error));
    }),
  );
};

export default primaryImageEpic;
