import React from 'react';
import { ADMIN_USERS_LIST_COPY } from './constants';
import { FrozenStyled } from './UserStatusStyled';

interface FrozenUserProps {
  reason?: string;
}

const FrozenUser: React.FC<FrozenUserProps> = ({ reason }: FrozenUserProps) => (
  <>
    <FrozenStyled>{ADMIN_USERS_LIST_COPY.activeFrozen} </FrozenStyled>
    {reason ? <span>({reason})</span> : null}
  </>
);

export default FrozenUser;
