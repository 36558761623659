import {
  map,
  switchMap,
  withLatestFrom,
  catchError,
  filter,
  delay,
} from 'rxjs/operators';
import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import isEmpty from 'ramda/src/isEmpty';

import { endpoints } from 'globalConstants';
import { getUserSearchStatisticId } from 'store/selectors/searchStatisticsSelectors';
import { AuthenticatedRequestObservable } from 'apis/request';
import {
  fetchSearchResultsStatisticsFail,
  fetchSearchResultsStatisticsPollingRetry,
  fetchSearchResultsStatisticsSuccess,
  SearchActionTypes,
} from 'store/actions/searchActions';
import { SearchStatisticsState } from 'store/reducers/searchStatisticsReducer';

type SearchStatisticsEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const searchStatisticsEpic: Epic = (
  action$,
  state$,
  { authRequest }: SearchStatisticsEpicDependencies,
) =>
  action$.pipe(
    ofType<any>(
      SearchActionTypes.CREATE_SEARCH_RESULTS_STATISTICS_API_SUCCESS,
      SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS_POLLING_RETRY,
    ),
    filter((action) => {
      return !isEmpty(action.payload);
    }),
    withLatestFrom(state$),
    delay(1000),
    switchMap(([, state]) => {
      const userSearchStatisticId = getUserSearchStatisticId(state);
      if (!userSearchStatisticId) {
        return [];
      }

      const url = `${endpoints.searchStatistics}/${userSearchStatisticId}`;

      return authRequest(state$, {
        method: 'GET',
        url,
      })().pipe(
        map((results) => {
          if (results.status !== 200) {
            throw new Error(`error loading ${url}`);
          }
          const response = results.response as SearchStatisticsState;

          if (!response.completed) {
            const currentRetries = state$.value.searchStatistics.retries ?? 0;
            response.retries = currentRetries + 1;

            if (currentRetries > 360) {
              throw Error('polling of results failed after 5 minutes');
            }

            return fetchSearchResultsStatisticsPollingRetry(response);
          }
          return fetchSearchResultsStatisticsSuccess(response);
        }),
        catchError(() => {
          return of(fetchSearchResultsStatisticsFail());
        }),
      );
    }),
  );
export default searchStatisticsEpic;
