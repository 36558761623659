import styled from 'styled-components/macro';

export const EpcSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const EpcSelectDivider = styled.div`
  padding: 20px;
  border-bottom: 1px solid
    ${(props) => props.theme.colorPalette.secondary.lightGrey};
`;

export const LoadingSpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ShowMoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ShowMoreButton = styled.button`
  cursor: pointer;
  padding: 4px 12px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;
