import React, { useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import useOutsideClick from 'hooks/useOutsideClick';
import { AccessibleText } from 'styled/Global';
import { KEYBOARD_EVENT_VALUES } from 'globalConstants';

import { globalModalHide } from 'store/actions/globalModalActions';
import { newRecordReset } from 'store/actions/newRecordActions';
import {
  getModalIsOpenSelector,
  getCurrentModalSelector,
  shouldRemainInPlace,
} from 'store/selectors/globalModalSelectors';

import {
  GlobalModalOverlayStyled,
  GlobalModalContainerStyled,
  GlobalModalCloseIcon,
  GlobalModalCloseButton,
  GlobalModalPageBlur,
} from './GlobalModalWrapperStyled';

import { GlobalModalWrapperProps } from './types';
import { GLOBAL_MODAL_COPY } from './constants';

const GlobalModalWrapper: React.FC<GlobalModalWrapperProps> = ({
  children,
  modalId,
  isClickableOutsideToClose = true,
  hasCloseButton = true,
  lightTheme = false,
  size = 'small',
  closeModalClicked,
}: GlobalModalWrapperProps) => {
  const modalRoot = document.getElementById('modal-root');

  const isOpen = useSelector(getModalIsOpenSelector);
  const disableWindowScroll = useSelector(shouldRemainInPlace);
  const currentModal = useSelector(getCurrentModalSelector);
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!disableWindowScroll) {
      window.scrollTo(0, 0);
    }

    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen, disableWindowScroll]);

  const handleOnClose = () => {
    if (isOpen) dispatch(globalModalHide());
    if (modalId.includes('thanks')) dispatch(newRecordReset());
    if (closeModalClicked) closeModalClicked();
  };

  const handleKeyboardEvents = useCallback(
    (event) => {
      const handleFocusTrap = () => {
        const focusableModalElements = modalRef.current
          ? modalRef.current.querySelectorAll<HTMLElement>('*')
          : null;

        if (!focusableModalElements) return true;
        const firstElement = focusableModalElements[0];
        const items = Array.from(focusableModalElements);

        if (document.activeElement && firstElement) {
          const isInModal = items.includes(
            (document.activeElement as unknown) as HTMLElement,
          );
          if (!isInModal) {
            firstElement.focus();
            return event.preventDefault();
          }
        }

        return true;
      };

      if (event.keyCode === KEYBOARD_EVENT_VALUES.ESCAPE) {
        if (isOpen && isClickableOutsideToClose) dispatch(globalModalHide());
      }

      if (event.keyCode === KEYBOARD_EVENT_VALUES.TAB) {
        handleFocusTrap();
      }
    },
    [dispatch, isOpen, isClickableOutsideToClose],
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyboardEvents);
    return () => {
      window.removeEventListener('keyup', handleKeyboardEvents);
    };
  }, [handleKeyboardEvents]);

  useOutsideClick(modalRef, () => handleOnClose(), isClickableOutsideToClose);

  if (modalRoot && isOpen && currentModal === modalId) {
    if (modalRef.current) modalRef.current.focus();

    return ReactDOM.createPortal(
      <>
        <GlobalModalPageBlur />
        <GlobalModalOverlayStyled lightTheme={lightTheme}>
          <GlobalModalContainerStyled ref={modalRef} size={size}>
            {hasCloseButton && (
              <GlobalModalCloseButton type="button" onClick={handleOnClose}>
                <GlobalModalCloseIcon />
                <AccessibleText>{GLOBAL_MODAL_COPY.closeButton}</AccessibleText>
              </GlobalModalCloseButton>
            )}
            {children}
          </GlobalModalContainerStyled>
        </GlobalModalOverlayStyled>
      </>,
      modalRoot,
    );
  }

  return null;
};

export default GlobalModalWrapper;
