import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  NegativeActionButton,
} from 'components/CallToActions/Button/Button';
import {
  GeneralModalTitle,
  ModalCardHeader,
  ModalCardMain,
  ModalCardWrapper,
} from 'components/ModalCard/ModalCard';
import GlobalModalWrapper from 'connected/GlobalModal/GlobalModalWrapper';

import { User } from 'services/auth';
import {
  globalModalShow,
  globalSingleModalHide,
} from 'store/actions/globalModalActions';
import {
  getCurrentModalSelector,
  getModalIsOpenSelector,
} from 'store/selectors/globalModalSelectors';
import Select from 'components/Forms/Inputs/Select/Select';
import Checkbox from 'components/Forms/Inputs/Checkbox/Checkbox';
import { freezeUser, unfreezeUser } from 'store/actions/usersListActions';
import { ADMIN_USERS_LIST_COPY, REASON_OPTIONS } from './constants';

import {
  StyledForm,
  FormCheckboxes,
  FormButtonWrapper,
  ChangeBtn,
  UserStatusCell,
} from './UserStatusStyled';
import ActiveUser from './ActiveUser';
import FrozenUser from './FrozenUser';

interface UserStatusProps {
  user: User;
}

const UserStatus: React.FC<UserStatusProps> = ({ user }: UserStatusProps) => {
  const modalId = `changeUserFreezeModal-${user.username}`;
  const [userStatus, setUserStatus] = useState({
    isActive: user.isActive,
    reason: user.reason,
  });
  const dispatch = useDispatch();
  const isOpen = useSelector(getModalIsOpenSelector);
  const currentModal = useSelector(getCurrentModalSelector);

  const showChangeModal = () => dispatch(globalModalShow(modalId));
  const onCloseModal = () => dispatch(globalSingleModalHide(modalId));

  const onSave = (userToUpdate: User) => {
    if (userStatus.isActive) {
      dispatch(
        unfreezeUser({
          username: userToUpdate.username,
        }),
      );
    }
    if (!userStatus.isActive) {
      dispatch(
        freezeUser({
          username: userToUpdate.username,
          reason: userStatus.reason || '',
        }),
      );
    }

    dispatch(globalSingleModalHide(modalId));
  };

  // Close modal on navigation out
  useEffect(() => {
    return () => {
      dispatch(globalSingleModalHide(modalId));
    };
  }, [dispatch, modalId]);

  return (
    <>
      <UserStatusCell>
        {user.isActive ? <ActiveUser /> : <FrozenUser reason={user.reason} />}
        <ChangeBtn type="button" onClick={showChangeModal}>
          Change
        </ChangeBtn>
      </UserStatusCell>

      {isOpen && currentModal === modalId && (
        <GlobalModalWrapper
          modalId={modalId}
          hasCloseButton={false}
          lightTheme
          isClickableOutsideToClose={false}
        >
          <ModalCardWrapper>
            <ModalCardHeader>
              <GeneralModalTitle>
                {ADMIN_USERS_LIST_COPY.modalTitle}
              </GeneralModalTitle>
            </ModalCardHeader>
            <ModalCardMain>
              <StyledForm>
                <h3>
                  {ADMIN_USERS_LIST_COPY.modalSubTitle}{' '}
                  <b>{user.name.display}</b>
                </h3>

                <FormCheckboxes>
                  <Checkbox
                    onChange={() =>
                      setUserStatus({ ...userStatus, isActive: true })
                    }
                    onKeyPress={() =>
                      setUserStatus({ ...userStatus, isActive: true })
                    }
                    name="active"
                    isChecked={userStatus.isActive}
                    value="active"
                    label="Active"
                  />
                  {` `}
                  <Checkbox
                    onChange={() =>
                      setUserStatus({ ...userStatus, isActive: false })
                    }
                    onKeyPress={() =>
                      setUserStatus({ ...userStatus, isActive: false })
                    }
                    name="frozen"
                    isChecked={!userStatus.isActive}
                    value="frozen"
                    label="Frozen"
                  />
                </FormCheckboxes>
                <br />

                {!userStatus.isActive && (
                  <Select
                    options={REASON_OPTIONS}
                    name="userFrozenReason"
                    label={ADMIN_USERS_LIST_COPY.reason}
                    onSelectChange={(option) =>
                      setUserStatus({ ...userStatus, reason: option.value })
                    }
                    selectedValue={
                      userStatus.reason
                        ? {
                            label: userStatus.reason,
                            value: userStatus.reason,
                          }
                        : undefined
                    }
                  />
                )}

                <FormButtonWrapper>
                  <NegativeActionButton onClick={onCloseModal}>
                    {ADMIN_USERS_LIST_COPY.btn.cancel}
                  </NegativeActionButton>
                  <Button
                    onClick={() => onSave(user)}
                    disabled={!userStatus.isActive && !userStatus.reason}
                  >
                    {ADMIN_USERS_LIST_COPY.btn.save}
                  </Button>
                </FormButtonWrapper>
              </StyledForm>
            </ModalCardMain>
          </ModalCardWrapper>
        </GlobalModalWrapper>
      )}
    </>
  );
};

export default UserStatus;
