import { GlobalApiFailError } from './types';

export enum FullScreenImageActionTypes {
  FETCH_FULLSCREEN_IMAGE_SIGNED_URL = 'FETCH_FULLSCREEN_IMAGE_SIGNED_URL',
  FETCH_FULLSCREEN_IMAGE_SIGNED_URL_SUCCESS = 'FETCH_FULLSCREEN_IMAGE_SIGNED_URL_SUCCESS',
  FETCH_FULLSCREEN_IMAGE_SIGNED_URL_FAIL = 'FETCH_FULLSCREEN_IMAGE_SIGNED_URL_FAIL',

  FULLSCREEN_IMAGE_LOADED = 'FULLSCREEN_IMAGE_LOADED',
  CLOSE_FULLSCREEN_IMAGE = 'CLOSE_FULLSCREEN_IMAGE',
  SET_FULLSCREEN_IMAGE_AS_PRIMARY = 'SET_FULLSCREEN_IMAGE_AS_PRIMARY',
}

export type FullScreenImagePayloadType = {
  entityId: string;
  filename: string;
  isPrimary?: boolean;
};

export type FetchFullscreenImageSignedUrlAction = {
  type: FullScreenImageActionTypes.FETCH_FULLSCREEN_IMAGE_SIGNED_URL;
  payload: FullScreenImagePayloadType;
};

export type FetchFullScreenImageSignedUrlSuccessAction = {
  type: FullScreenImageActionTypes.FETCH_FULLSCREEN_IMAGE_SIGNED_URL_SUCCESS;
  payload: string;
};

export type FetchFullScreenImageSignedUrlFailAction = {
  type: FullScreenImageActionTypes.FETCH_FULLSCREEN_IMAGE_SIGNED_URL_FAIL;
  payload: GlobalApiFailError;
};

export type FullScreenImageLoadedAction = {
  type: FullScreenImageActionTypes.FULLSCREEN_IMAGE_LOADED;
};

export type CloseFullScreenImageAction = {
  type: FullScreenImageActionTypes.CLOSE_FULLSCREEN_IMAGE;
};

export type SetFullScreenImageAsPrimaryAction = {
  type: FullScreenImageActionTypes.SET_FULLSCREEN_IMAGE_AS_PRIMARY;
};

export const fetchFullScreenImageSignedUrl = (
  payload: FullScreenImagePayloadType,
): FetchFullscreenImageSignedUrlAction => ({
  type: FullScreenImageActionTypes.FETCH_FULLSCREEN_IMAGE_SIGNED_URL,
  payload,
});

export const fetchFullScreenImageSignedUrlSuccess = (
  payload: string,
): FetchFullScreenImageSignedUrlSuccessAction => ({
  type: FullScreenImageActionTypes.FETCH_FULLSCREEN_IMAGE_SIGNED_URL_SUCCESS,
  payload,
});

export const fetchFullScreenImageSignedUrlFail = (
  payload: GlobalApiFailError,
): FetchFullScreenImageSignedUrlFailAction => ({
  type: FullScreenImageActionTypes.FETCH_FULLSCREEN_IMAGE_SIGNED_URL_FAIL,
  payload,
});

export const fullScreenImageLoaded = (): FullScreenImageLoadedAction => ({
  type: FullScreenImageActionTypes.FULLSCREEN_IMAGE_LOADED,
});

export const closeFullScreenImage = (): CloseFullScreenImageAction => ({
  type: FullScreenImageActionTypes.CLOSE_FULLSCREEN_IMAGE,
});

export const setFullScreenImageAsPrimary = (): SetFullScreenImageAsPrimaryAction => ({
  type: FullScreenImageActionTypes.SET_FULLSCREEN_IMAGE_AS_PRIMARY,
});

export type FullScreenImageActions =
  | FetchFullscreenImageSignedUrlAction
  | FetchFullScreenImageSignedUrlSuccessAction
  | FetchFullScreenImageSignedUrlFailAction
  | FullScreenImageLoadedAction
  | CloseFullScreenImageAction
  | SetFullScreenImageAsPrimaryAction;
