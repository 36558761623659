import React, { ChangeEvent, FocusEvent, RefObject } from 'react';

import debounce from 'utils/debounce';
import { INPUT_FORMAT_TYPE, globalContent } from 'globalConstants';

import useFieldInputValidation from 'hooks/useFieldInputValidation';
import RequiredFlag from 'components/Forms/RequiredFlag';
import FieldValidation from 'components/Forms/FieldValidation';
import { FIELD_COMPONENTS, ValidationProps } from 'connected/FormFlow/types';
import { StyledInputProps } from './types';
import {
  StyledInput,
  StyledInputField,
  StyledLabel,
  StyledNumberFormat,
} from './TextInputStyled';
import { StyledPrompt } from '../Select/SelectStyled';
import { getPlural } from './helpers';

interface TextInputProps extends StyledInputProps {
  label: string;
  name: string;
  componentType: FIELD_COMPONENTS;
  value?: string | number;
  validation?: ValidationProps;
  placeholder?: string;
  formatInput?: INPUT_FORMAT_TYPE;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  labelIsHidden?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  isDisabled?: boolean;
  allowNegative?: boolean;
  numberFormat?: string;
  decimalScale?: number;
  isHidden?: boolean;
  prompt?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  name,
  componentType,
  placeholder,
  onChange,
  onFocus,
  validation,
  formatInput,
  labelIsHidden,
  customStyles,
  inputRef,
  value,
  isDisabled,
  allowNegative = false,
  numberFormat,
  decimalScale = 0,
  isHidden = false,
  prompt,
}: TextInputProps) => {
  const { hasErrors } = useFieldInputValidation(
    value,
    componentType,
    validation,
  );
  const isLabelMinimized = !!value || value === 0 || !!placeholder;
  const inputType = isHidden ? 'hidden' : 'text';

  const handleOnChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
  }, 200);

  const getFormattedInput = (type: INPUT_FORMAT_TYPE) => {
    const componentProps = {
      id: name,
      value,
      disabled: isDisabled,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        handleOnChange(e);
      },
      autoComplete: 'off',
      allowNegative: false,
      format: numberFormat,
      placeholder,
    };

    switch (type) {
      case INPUT_FORMAT_TYPE.CURRENCY: {
        return (
          <StyledNumberFormat
            thousandSeparator={globalContent.thousandSeparator}
            decimalScale={decimalScale}
            prefix={globalContent.currency}
            {...componentProps}
          />
        );
      }
      case INPUT_FORMAT_TYPE.PERCENTAGE: {
        return (
          <StyledNumberFormat
            suffix="%"
            decimalSeparator={globalContent.decimalSeparator}
            decimalScale={2}
            {...componentProps}
          />
        );
      }
      case INPUT_FORMAT_TYPE.NUMERIC: {
        return (
          <StyledNumberFormat {...componentProps} decimalScale={decimalScale} />
        );
      }
      case INPUT_FORMAT_TYPE.INTEGER: {
        return <StyledNumberFormat {...componentProps} decimalScale={0} />;
      }
      case INPUT_FORMAT_TYPE.METERS: {
        return (
          <StyledNumberFormat
            thousandSeparator={globalContent.thousandSeparator}
            suffix={globalContent.meters}
            decimalScale={2}
            {...componentProps}
          />
        );
      }
      case INPUT_FORMAT_TYPE.YEAR: {
        return (
          <StyledNumberFormat
            decimalSeparator={false}
            suffix={getPlural(
              globalContent.year,
              globalContent.years,
              value as string,
            )}
            {...componentProps}
          />
        );
      }
      case INPUT_FORMAT_TYPE.SQUARE_FT: {
        return (
          <StyledNumberFormat
            id={name}
            value={value}
            decimalSeparator={false}
            suffix={` ${globalContent.squareFt}`}
            disabled={isDisabled}
            onChange={componentProps.onChange}
            allowNegative={allowNegative}
            thousandSeparator={globalContent.thousandSeparator}
            placeholder={placeholder}
            autoComplete="off"
          />
        );
      }
      case INPUT_FORMAT_TYPE.PRICE_PER_SQ_FT: {
        return (
          <StyledNumberFormat
            id={name}
            value={value}
            prefix={globalContent.currency}
            suffix={` ${globalContent.perSqFt}`}
            decimalScale={decimalScale}
            disabled={isDisabled}
            onChange={componentProps.onChange}
            allowNegative={allowNegative}
            thousandSeparator={globalContent.thousandSeparator}
            autoComplete="off"
            placeholder={placeholder}
          />
        );
      }
      case INPUT_FORMAT_TYPE.MONTH: {
        return (
          <StyledNumberFormat
            id={name}
            value={value}
            decimalSeparator={false}
            allowNegative={allowNegative}
            suffix={getPlural(
              globalContent.month,
              globalContent.months,
              value as string,
            )}
            disabled={isDisabled}
            onChange={componentProps.onChange}
            autoComplete="off"
          />
        );
      }
      default: {
        return <StyledNumberFormat {...componentProps} />;
      }
    }
  };

  return (
    <div>
      {prompt && <StyledPrompt>{prompt}</StyledPrompt>}
      <StyledInputField
        isDisabled={isDisabled || false}
        hasErrors={hasErrors || false}
        isHidden={isHidden || false}
      >
        {formatInput ? (
          getFormattedInput(formatInput)
        ) : (
          <StyledInput
            id={name}
            name={name}
            type={inputType}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.persist();
              if (onChange) onChange(e);
            }}
            onFocus={onFocus}
            placeholder={placeholder}
            autoComplete="off"
            customStyles={customStyles}
            ref={inputRef}
            value={value}
            disabled={isDisabled}
          />
        )}
        <StyledLabel
          labelIsMinimized={isLabelMinimized}
          htmlFor={name}
          labelIsHidden={labelIsHidden}
        >
          {label}
          {validation?.isRequired && <RequiredFlag />}
        </StyledLabel>
      </StyledInputField>
      {validation && (
        <FieldValidation validation={validation} hasErrors={hasErrors} />
      )}
    </div>
  );
};

export default TextInput;
