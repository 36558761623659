/* eslint-disable no-nested-ternary */
import React from 'react';

import CRMLink from 'components/CallToActions/CRMLink/CRMLink';
import InlineActionMenu from 'components/ActionsMenu/InlineActionMenu';
import AnnualRentIcon from 'components/DataValueItem/AnnualRentIcon';
import Tooltip from 'components/Tooltip/Tooltip';
import { Vertical } from 'globalConstants';
import CRMNearestLink from 'components/CallToActions/CRMNearestLink/CRMNearestLink';
import { IntrohiveRSSPill } from 'components/CallToActions/IntrohivePill/IntrohivePill';
import Checkbox from 'components/Forms/Inputs/Checkbox/Checkbox';
import {
  PDFIcon,
  ImageIcon,
  SpreadsheetIcon,
  DocumentIcon,
  TableCellStyled,
  ActionCellWrapper,
  StyledHeadingCell,
  Tagline,
  StyledLink,
  MatchingIcon,
} from './TableStyled';

import {
  Actions,
  TableCellHeading,
  TableCellProps,
  DocumentFileTypes,
} from './types';

import ActionCell from './ActionCell';
import { TABLE_ACTIONS, TABLE_CELL_TYPE, TABLE_COPY } from './constants';
import IconCell from './IconCell';
import ModalTriggerCell from './ModalTriggerCell';

export const getFileIcon = (type: DocumentFileTypes) => {
  switch (type) {
    case DocumentFileTypes.pdf: {
      return <PDFIcon />;
    }

    case DocumentFileTypes.jpeg:
    case DocumentFileTypes.jpg:
    case DocumentFileTypes.png:
    case DocumentFileTypes.bmp:
    case DocumentFileTypes.gif: {
      return <ImageIcon />;
    }

    case DocumentFileTypes.xls:
    case DocumentFileTypes.xlsx: {
      return <SpreadsheetIcon />;
    }

    default: {
      return <DocumentIcon />;
    }
  }
};

const getIcon = (indicator?: string) => {
  if (!indicator) {
    return <></>;
  }
  return <MatchingIcon indicator={indicator} />;
};

const HeadingWithOptionalIcon: React.FC<TableCellHeading> = ({
  value,
  icon,
  isBold = false,
  tagline,
  linkTo,
  savCrmId,
  savCrmNearestId,
  isConfirmedTotalRent,
  cellKey,
  hasMatchingRecords,
  introhiveRelationshipStrengthScore,
  introhiveNearestRelationshipStrengthScore,
  introhiveScoreId,
}: TableCellHeading) => {
  const matchingIcon = getIcon(hasMatchingRecords?.indicator);
  const copy =
    hasMatchingRecords?.vertical === Vertical.Lease
      ? TABLE_COPY.tooltip.mergeRecords.lease
      : TABLE_COPY.tooltip.mergeRecords.sale;

  const renderValue = () => {
    if (typeof value === 'boolean') {
      return value ? '✓' : '';
    }

    return <>{value || '-'}</>;
  };

  const Heading = () => (
    <StyledHeadingCell shouldWrap={!!tagline} isBold={isBold}>
      {icon && <span>{getFileIcon(icon)}</span>}
      <span>{renderValue()}</span>
      {savCrmId && <CRMLink crmId={savCrmId} />}
      {savCrmNearestId && <CRMNearestLink crmNearestId={savCrmNearestId} />}
      {(introhiveRelationshipStrengthScore ||
        introhiveNearestRelationshipStrengthScore) && (
        <IntrohiveRSSPill
          value={introhiveRelationshipStrengthScore}
          valueNearest={introhiveNearestRelationshipStrengthScore}
          introhiveScoreId={introhiveScoreId}
        />
      )}
      {tagline && <Tagline>({tagline})</Tagline>}
      {value !== undefined && value !== null && (
        <AnnualRentIcon
          isInCell
          point={{ key: cellKey, isConfirmedTotalRent }}
        />
      )}
      {hasMatchingRecords &&
        hasMatchingRecords?.matches &&
        hasMatchingRecords?.matches.length > 1 && (
          <Tooltip tooltipId={`MatchingIcon_${cellKey}`} button={matchingIcon}>
            {copy}
          </Tooltip>
        )}
    </StyledHeadingCell>
  );

  if (linkTo) {
    return (
      <StyledLink to={linkTo}>
        <Heading />
      </StyledLink>
    );
  }
  return <Heading />;
};

const TableCell: React.FC<TableCellProps> = ({
  actions,
  icon,
  value,
  values,
  tagline,
  linkTo,
  hasHighlight,
  savCrmId,
  savCrmNearestId,
  cellType,
  isConfirmedTotalRent,
  cellKey,
  hasMatchingRecords,
  vertical,
  introhiveName,
  introhiveRelationshipStrengthScore,
  introhiveNearestRelationshipStrengthScore,
  subValue,
  onChange,
}: TableCellProps) => {
  const isIconCell =
    actions &&
    actions?.length > 0 &&
    actions[0].type === TABLE_ACTIONS.DISPUTED;

  const getCell = () => {
    if (cellType === TABLE_CELL_TYPE.ACTION) {
      return (
        <ActionCellWrapper>
          <InlineActionMenu>
            {actions?.map((action: Actions, i: number) => (
              <ActionCell
                key={`${action.type}_${cellKey}_${i}`}
                handleAction={action.handleAction}
                link={action.link}
                type={action.type}
                isDisabled={action.isDisabled}
                showTooltipIfDisabled={action.showTooltipIfDisabled}
                isAdminOnly={action.isAdminOnly}
                modalConfig={action.modalConfig}
              />
            ))}
          </InlineActionMenu>
        </ActionCellWrapper>
      );
    }

    if (cellType === TABLE_CELL_TYPE.ICON) {
      return (
        <ActionCellWrapper isIconCell={isIconCell}>
          {actions?.map((action: Actions) => (
            <IconCell
              key={`${action.type}_${action.link}`}
              handleAction={action.handleAction}
              link={action.link}
              type={action.type}
              isDisabled={action.isDisabled}
              showTooltipIfDisabled={action.showTooltipIfDisabled}
              tooltipContent={action?.tooltipContent}
              id={action.id}
              isAdminOnly={action.isAdminOnly}
              vertical={vertical}
            />
          ))}
        </ActionCellWrapper>
      );
    }

    if (cellType === TABLE_CELL_TYPE.CHECKBOX) {
      const onCheckboxChange = (
        _: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
      ) => onChange?.(checked);
      return (
        <ActionCellWrapper isIconCell={isIconCell}>
          <Checkbox
            label=""
            name={value}
            value={subValue}
            isChecked={subValue}
            onChange={onCheckboxChange}
            onKeyPress={() => {}}
          />
        </ActionCellWrapper>
      );
    }

    if (cellType === TABLE_CELL_TYPE.MODAL_TRIGGER && !values && !value) {
      return (
        <ActionCellWrapper>
          {actions?.map((action: Actions) => (
            <ModalTriggerCell
              key={`${action.type}_${action.link}`}
              handleAction={action.handleAction}
              link={action.link}
              type={action.type}
              isDisabled={action.isDisabled}
              showTooltipIfDisabled={action.showTooltipIfDisabled}
              modalConfig={action.modalConfig}
            />
          ))}
        </ActionCellWrapper>
      );
    }
    if (values) {
      return (
        <>
          {values.map((item: any) => (
            <HeadingWithOptionalIcon
              icon={icon}
              key={item.value}
              isBold={item.isBold}
              value={item.value}
              tagline={item.tagline}
              linkTo={item.linkTo}
              savCrmId={item.savCrmId}
              savCrmNearestId={item.savCrmNearestId}
              hasMatchingRecords={item.hasMatchingRecords}
              introhiveName={item.introhiveName}
              introhiveRelationshipStrengthScore={
                item.introhiveRelationshipStrengthScore
              }
              introhiveNearestRelationshipStrengthScore={
                item.introhiveNearestRelationshipStrengthScore
              }
            />
          ))}
        </>
      );
    }

    return (
      <HeadingWithOptionalIcon
        icon={icon}
        linkTo={linkTo}
        value={value}
        tagline={tagline}
        savCrmId={savCrmId}
        savCrmNearestId={savCrmNearestId}
        cellKey={cellKey}
        hasMatchingRecords={hasMatchingRecords}
        isConfirmedTotalRent={isConfirmedTotalRent}
        introhiveName={introhiveName}
        introhiveRelationshipStrengthScore={introhiveRelationshipStrengthScore}
        introhiveNearestRelationshipStrengthScore={
          introhiveNearestRelationshipStrengthScore
        }
      />
    );
  };
  return (
    <TableCellStyled
      isIconCell={isIconCell}
      hasHighlight={hasHighlight}
      textAlign={actions ? 'right' : 'left'}
    >
      <span>{getCell()}</span>
    </TableCellStyled>
  );
};

export default TableCell;
