import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import SortPaginationTable from 'components/Table/SortPaginationTable';
import { TableRowProps } from 'components/Table/types';
import { User } from 'services/auth';
import { formatBooleanishYesNo } from 'services/format';
import { ADMIN_USER_LIST_HEADERS, ADMIN_USERS_LIST_COPY } from './constants';
import { NoUsersFoundStyled } from './AdminUsersPanelTableStyled';
import UserStatus from './UserStatus';

export const mapUserToTableRows = (users: User[]): TableRowProps[] => {
  return (
    users &&
    users.map((user) => {
      return {
        id: {
          value: uuid(),
        },
        firstName: {
          value: user.name.first,
        },
        lastName: {
          value: user.name.last,
        },
        email: {
          value: user.email,
        },
        isAdmin: {
          value: formatBooleanishYesNo(user.isAdmin),
        },
        status: {
          value: <UserStatus user={user} />,
        },
      };
    })
  );
};

interface AdminUsersPanelTableProps {
  users?: User[];
}

const AdminUsersPanelTable: React.FC<AdminUsersPanelTableProps> = ({
  users,
}: AdminUsersPanelTableProps) => {
  const usersDataRows = useMemo(() => {
    if (!users || users.length === 0) return [];
    return mapUserToTableRows(users);
  }, [users]);

  if (!users || users.length === 0)
    return (
      <NoUsersFoundStyled>
        {ADMIN_USERS_LIST_COPY.noUsersFound}
      </NoUsersFoundStyled>
    );

  return (
    <SortPaginationTable
      columns={9}
      tableId="adminTable"
      rows={usersDataRows}
      headers={ADMIN_USER_LIST_HEADERS}
    />
  );
};

export default AdminUsersPanelTable;
