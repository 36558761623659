import {
  FullScreenImageActions,
  FullScreenImageActionTypes,
} from 'store/actions/fullScreenImageActions';

export type FullScreenImageState = {
  isLoading: boolean;
  entityId: string;
  filename: string;
  signedUrl: string;
  isPrimary?: boolean;
  error?: string;
};

export const initialState: FullScreenImageState = {
  isLoading: false,
  entityId: '',
  filename: '',
  signedUrl: '',
  isPrimary: false,
  error: '',
};

const fullScreenImageReducer = (
  state = initialState,
  action: FullScreenImageActions,
) => {
  switch (action.type) {
    case FullScreenImageActionTypes.FETCH_FULLSCREEN_IMAGE_SIGNED_URL: {
      return {
        ...state,
        isLoading: true,
        entityId: action.payload.entityId,
        filename: action.payload.filename,
        isPrimary: action.payload.isPrimary || false,
      };
    }
    case FullScreenImageActionTypes.FETCH_FULLSCREEN_IMAGE_SIGNED_URL_SUCCESS: {
      return {
        ...state,
        signedUrl: action.payload,
      };
    }
    case FullScreenImageActionTypes.FETCH_FULLSCREEN_IMAGE_SIGNED_URL_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case FullScreenImageActionTypes.FULLSCREEN_IMAGE_LOADED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case FullScreenImageActionTypes.CLOSE_FULLSCREEN_IMAGE: {
      return {
        ...state,
        ...initialState,
      };
    }
    case FullScreenImageActionTypes.SET_FULLSCREEN_IMAGE_AS_PRIMARY: {
      return {
        ...state,
        isPrimary: true,
      };
    }
    default:
      return state;
  }
};

export default fullScreenImageReducer;
