import React from 'react';
import { ErrorPillWrapper, InfoIcon } from './ErrorPillStyled';

const ErrorPill: React.FC = ({ children }) => (
  <ErrorPillWrapper>
    <InfoIcon />
    {children}
  </ErrorPillWrapper>
);

export default ErrorPill;
