import { Reducer } from 'redux';

import { SearchActions, SearchActionTypes } from 'store/actions/searchActions';

export interface SearchStatisticsResultState {
  key?: string;
  rangeLow?: number;
  rangeHigh?: number;
  validRows?: number;
  statisticValue?: number;
}

export interface SearchStatisticsState {
  userSearchStatisticId?: string | null;
  isCalculating: boolean;
  isError: boolean;
  created?: string | null;
  updated?: string | null;
  completed?: string | null;
  pages?: number;
  page?: number;
  results?: SearchStatisticsResultState[];
  retries?: number;
  pageSize?: number;
  query?: string;
  isPanelExpanded: boolean;
}

export const initialState: SearchStatisticsState = {
  userSearchStatisticId: null,
  isCalculating: false,
  isError: false,
  page: 0,
  pages: 0,
  results: [],
  retries: 0,
  isPanelExpanded: false,
  completed: null,
};

type IncomingReducerActions = SearchActions;

const searchStatisticsReducer: Reducer<
  SearchStatisticsState,
  IncomingReducerActions
> = (state = initialState, action) => {
  switch (action.type) {
    case SearchActionTypes.CREATE_SEARCH_RESULTS_STATISTICS: {
      return {
        ...state,
        isCalculating: true,
        isError: false,
      };
    }
    case SearchActionTypes.CREATE_SEARCH_RESULTS_STATISTICS_API_SUCCESS: {
      return {
        ...state,
        userSearchStatisticId: action.payload.userSearchStatisticId,
      };
    }
    case SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS_POLLING_RETRY: {
      return {
        ...state,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        retries: action.payload.retries,
      };
    }
    case SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS_API_SUCCESS: {
      return {
        ...state,
        isCalculating: false,
        isError: false,
        results: action.payload.results,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        retries: 0,
      };
    }
    case SearchActionTypes.SEARCH_STATISTICS_TOGGLE_PANEL: {
      return {
        ...state,
        isPanelExpanded: action.payload,
      };
    }
    case SearchActionTypes.SEARCH_STATISTICS_RESET: {
      return {
        ...state,
        userSearchStatisticId: undefined,
        isError: false,
      };
    }
    case SearchActionTypes.FETCH_SEARCH_RESULTS_STATISTICS_API_FAIL: {
      return {
        ...initialState,
        isCalculating: false,
        isError: true,
      };
    }

    case SearchActionTypes.RESET_SEARCH_RESULTS: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default searchStatisticsReducer;
