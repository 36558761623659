import React, { useEffect } from 'react';
import FieldValidation from 'components/Forms/FieldValidation';
import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import useFieldInputValidation from 'hooks/useFieldInputValidation';

import RequiredFlag from 'components/Forms/RequiredFlag';
import { defaultSelectValue } from './constants';
import { SelectProps } from './types';
import {
  Label,
  StyledInputField,
  StyledSelect,
  StyledPrompt,
} from './SelectStyled';
import BlankOption from './BlankOption';
import { StyledHeader } from '../LargeRadio/LargeRadioStyled';

const Select: React.FC<SelectProps> = ({
  options,
  onSelectChange,
  selectedValue = defaultSelectValue,
  defaultValue = defaultSelectValue,
  label,
  isInlineSelect,
  name,
  disabled,
  placeholder,
  validation,
  labelIsHidden,
  hasDefaultValue,
  hideBlankOption,
  description,
  prompt,
}: SelectProps) => {
  useEffect(() => {
    if (hasDefaultValue && onSelectChange && selectedValue?.value)
      onSelectChange(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { hasErrors } = useFieldInputValidation(
    selectedValue.value,
    FIELD_COMPONENTS.SELECT,
    validation,
  );
  const isLabelMinimized =
    (!!selectedValue.value && selectedValue.value !== '') || !!placeholder;

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const element = event.currentTarget;
    const { value } = element;
    const optionToSet = options.find((option) => option.value === value) || {
      value: '',
      label: '',
    };

    if (optionToSet && onSelectChange) {
      onSelectChange(optionToSet);
    }
  };

  return (
    <div>
      {prompt && <StyledPrompt>{prompt}</StyledPrompt>}
      <StyledHeader>{description}</StyledHeader>
      <StyledInputField
        isDisabled={disabled || false}
        hasErrors={hasErrors || false}
        isFilled={!!(selectedValue && selectedValue.value)}
      >
        <StyledSelect
          isInlineSelect={isInlineSelect || false}
          onChange={onChange}
          id={name}
          value={selectedValue && selectedValue.value}
          disabled={disabled}
          labelIsHidden={labelIsHidden}
        >
          <BlankOption
            selectedValue={selectedValue}
            hideBlankOption={hideBlankOption}
            defaultValue={defaultValue}
          />

          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </StyledSelect>
        <Label
          htmlFor={name}
          labelIsMinimized={isLabelMinimized}
          labelIsHidden={labelIsHidden}
        >
          {label}
          {validation?.isRequired && <RequiredFlag />}
        </Label>
      </StyledInputField>
      {validation && (
        <FieldValidation hasErrors={hasErrors} validation={validation} />
      )}
    </div>
  );
};

export default Select;
