import { useEffect, RefObject } from 'react';
import { KEYBOARD_EVENT_VALUES } from 'globalConstants';

const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: Function,
  shouldRun: boolean,
  useTab?: boolean,
) => {
  const onHandleClick = (e: Event) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback(e.target);
    }
  };

  const onHandleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KEYBOARD_EVENT_VALUES.TAB) {
      onHandleClick(e);
    }
  };

  useEffect(() => {
    if (shouldRun) {
      document.addEventListener('click', onHandleClick);

      if (useTab) {
        document.addEventListener('keyup', onHandleKeyDown);
      }

      return () => {
        document.removeEventListener('click', onHandleClick);

        if (useTab) {
          document.removeEventListener('keyup', onHandleKeyDown);
        }
      };
    }

    return undefined;
  });
};

export default useOutsideClick;
