import React, { ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { epcSelectQuery, epcSelectReset } from 'store/actions/epcSelectActions';
import { RootState } from 'store/rootReducer';
import TextInput from 'components/Forms/Inputs/TextInput/TextInput';
import { FIELD_COMPONENTS } from 'connected/FormFlow/types';
import { LoadingSpinner } from 'components/Spinner/Spinner';
import {
  EpcSelectContainer,
  EpcSelectDivider,
  LoadingSpinnerContainer,
  ShowMoreButton,
  ShowMoreButtonContainer,
} from './EpcSelectStyled';
import { EPC_SELECT_COPY } from './constants';
import EpcSelectResultsTable from './table/EpcSelectResultsTable';

interface EpcSelectProps {
  onChange: (options: any) => void;
}

const EpcSelect: React.FC<EpcSelectProps> = ({ onChange }: EpcSelectProps) => {
  const dispatch = useDispatch();

  const {
    result: { results, meta },
    searchTerm,
    isFetching,
  } = useSelector((state: RootState) => state.epcSelect);

  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const pageNumber = meta?.page ?? 0;
  const totalPages = meta?.pages ?? 0;
  const canShowMoreResults = !isFetching && totalPages > pageNumber + 1;

  const resetSelectedIds = () => {
    onChange([]);
    setSelectedIds([]);
  };

  const resetOnUnMount = () => {
    return () => {
      resetSelectedIds();
      dispatch(epcSelectReset());
    };
  };

  useEffect(resetOnUnMount, []);

  const handleChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    resetSelectedIds();
    dispatch(epcSelectQuery(changeEvent.target.value));
  };

  const handleShowMoreClick = () =>
    dispatch(epcSelectQuery(searchTerm, (meta?.page ?? 0) + 1));

  const handleCheckboxChange = (id: string, checked: boolean) => {
    const newSelectedIds = checked
      ? [...selectedIds, id]
      : selectedIds.filter((selectedId) => selectedId !== id);
    onChange(newSelectedIds);
    setSelectedIds(newSelectedIds);
  };

  return (
    <EpcSelectContainer>
      <TextInput
        label={EPC_SELECT_COPY.label}
        name="epc-address"
        componentType={FIELD_COMPONENTS.TEXT_INPUT}
        onChange={handleChange}
        value={searchTerm}
      />
      <EpcSelectDivider />
      <EpcSelectResultsTable
        results={results}
        selectedIds={selectedIds}
        onCheckboxChange={handleCheckboxChange}
      />
      {isFetching && (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      )}
      {canShowMoreResults && (
        <ShowMoreButtonContainer>
          <ShowMoreButton onClick={handleShowMoreClick}>
            {EPC_SELECT_COPY.showMore}
          </ShowMoreButton>
        </ShowMoreButtonContainer>
      )}
    </EpcSelectContainer>
  );
};

export default EpcSelect;
