import React from 'react';

import {
  QuickSearchResult,
  QuickSearchOnResultItemClick,
  QuickSearchResultBuilding,
  QuickSearchResultCompany,
  QuickSearchColorMode,
} from 'connected/QuickSearch/types';
import { Vertical } from 'globalConstants';
import {
  ResultListItem,
  ResultAddress,
  ResultAddressWrapper,
  ChevronRightIcon,
  OfficeIcon,
  IndustrialIcon,
  RetailIcon,
  SubDirectoryIcon,
} from './ResultItemStyled';
import InfoPill, { InfoPillProps } from '../InfoPill/InfoPill';
import ResultItemCta from './ResultItemCta';

export interface ResultItemProps {
  result: QuickSearchResult;
  onResultItemClick?: QuickSearchOnResultItemClick;
  isChild?: boolean;
  mode?: QuickSearchColorMode;
  disableNonCanonicalResults?: boolean;
}

const renderPrimaryUseIcon = (primaryUse: string) => {
  switch (primaryUse) {
    case 'office': {
      return <OfficeIcon />;
    }

    case 'industrial': {
      return <IndustrialIcon />;
    }

    case 'retail': {
      return <RetailIcon />;
    }

    default: {
      return <OfficeIcon />;
    }
  }
};

const renderInfoPill = (result: QuickSearchResultBuilding) => {
  let props: InfoPillProps | null = {
    id: result.id,
    searchType: result.type,
    type: 'alias',
    text: '',
  };

  switch (true) {
    case result.alternativeAddress !== undefined: {
      props.type = 'alternativeAddress';
      props.text = result.alternativeAddress || '';
      break;
    }

    case result.formerName !== undefined: {
      props.type = 'formerName';
      props.text = result.formerName || '';
      break;
    }

    case result.alias !== undefined: {
      props.type = 'alias';
      props.text = result.alias || '';
      break;
    }

    default: {
      props = null;
    }
  }

  return props && <InfoPill {...props} />;
};

const BuildingResult = (building: QuickSearchResultBuilding) => (
  <>
    <ResultAddressWrapper>
      <div>{renderPrimaryUseIcon(building.primaryUse)}</div>
      <ResultAddress>{building.address}</ResultAddress>
    </ResultAddressWrapper>
    {renderInfoPill(building)}
  </>
);

const CompanyResult = ({ name, address }: QuickSearchResultCompany) => (
  <ResultAddressWrapper>
    <OfficeIcon />
    <ResultAddress>{address ? `${name}, ${address}` : name}</ResultAddress>
  </ResultAddressWrapper>
);

const ResultItem: React.FC<ResultItemProps> = ({
  result,
  onResultItemClick,
  isChild,
  mode,
  disableNonCanonicalResults,
}: ResultItemProps) => (
  <ResultListItem isChild={isChild}>
    {isChild && <SubDirectoryIcon mode={mode} />}
    <ResultItemCta
      vertical={result.type}
      entityId={result.id}
      entity={result}
      onResultItemClick={onResultItemClick}
      isChild={isChild}
      isDisabled={disableNonCanonicalResults}
    >
      {result.type === Vertical.Building ? (
        <BuildingResult {...result} />
      ) : (
        <CompanyResult {...result} />
      )}
      <ChevronRightIcon />
    </ResultItemCta>
  </ResultListItem>
);

export default ResultItem;
