import AddressCard from 'components/AddressCard/AddressCard';
import { SIDEBAR_CARD_TYPES } from 'connected/FormFlow/FormSidebar/types';
import React from 'react';
import { DetailsSummary } from 'pages/Details/types';
import MoveRecordSidebarCard from './MoveRecordSidebarCard';

export interface SidebarCardProps {
  name?: string;
  address?: DetailsSummary;
  content?: React.ReactNode;
  sidebarCardType?: SIDEBAR_CARD_TYPES;
}

const SidebarCard: React.FC<SidebarCardProps> = ({
  address,
  name,
  content,
  sidebarCardType,
}: SidebarCardProps) => {
  if (sidebarCardType && sidebarCardType === SIDEBAR_CARD_TYPES.MOVE_RECORD) {
    return <MoveRecordSidebarCard />;
  }
  if (content) {
    return <>{content}</>;
  }
  if (address) {
    return <AddressCard isSquare address={address} />;
  }
  if (name) {
    return <span>{name}</span>;
  }
  return null;
};

export default SidebarCard;
