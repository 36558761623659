import { SortTypes } from 'components/Table/types';

export const ADMIN_USERS_LIST_COPY = {
  title: 'Users',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  adminUser: 'Admin User',
  status: 'Status',
  noUsersFound: 'No users found',
  btn: {
    cancel: 'Cancel',
    save: 'Save',
  },
  activeFrozen: 'Frozen',
  activeStatus: 'Active',
  modalTitle: 'Change User Status',
  modalSubTitle: 'Select a new status for ',
  reason: 'Reason',
};

export const ADMIN_USER_LIST_HEADERS = [
  {
    title: ADMIN_USERS_LIST_COPY.firstName,
    isSortable: {
      type: SortTypes.string,
      key: 'firstName',
    },
  },
  {
    title: ADMIN_USERS_LIST_COPY.lastName,
    isSortable: {
      type: SortTypes.string,
      key: 'lastName',
    },
  },
  {
    title: ADMIN_USERS_LIST_COPY.email,
    isSortable: {
      type: SortTypes.string,
      key: 'email',
    },
  },
  {
    title: ADMIN_USERS_LIST_COPY.adminUser,
    isSortable: {
      type: SortTypes.string,
      key: 'isAdmin',
    },
  },
  {
    title: ADMIN_USERS_LIST_COPY.status,
  },
];

export const REASON_OPTIONS = [
  { value: 'No longer with Savills', label: 'No longer with Savills' },
  { value: 'Under investigation', label: 'Under investigation' },
];
