import React, { useMemo, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { priorExistingRecordsSelector } from 'store/selectors/detailsSelectors';
import {
  setFormFlowSection,
  initializeFormFlow,
  resetFormFlow,
} from 'store/actions/formBuilderActions';
import useViewValidation from './hooks/useValidation';
import {
  FormFlowErrors,
  StyledForm,
  StyledFormMain,
} from './FormFlowBetaStyled';
import FormView from './FormView/FormView';
import FormSidebar from './FormSidebar/FormSidebar';
import FormNavigation from './FormNavigation/FormNavigation';
import {
  constructInitialFormState,
  getActiveViewConfigs,
} from './services/formState';
import { getFindMatchesProps } from './services/matches';
import {
  evaluateConditions,
  uniqueViews,
  viewsToDiscard,
} from './services/conditions';
import {
  activeSectionSelector,
  previousSectionSelector,
} from './selectors/formStateSelector';
import {
  FormFlowProps,
  ViewConfig,
  FieldOnChangeHandler,
  SourceOption,
  FindMatchesProps,
  VIEW_TYPES,
} from './types';
import { formStateReducer } from './reducers/formBuilderStateReducer';
import { FormStateContext } from './context';
import { FORM_FLOWS } from './constants';
import FormErrors from './FormErrors/FormErrors';
import LessorView from './LessorView/LessorView';

const FormFlowBeta = ({
  formId,
  configs,
  renderCard,
  handleClose,
  handleSubmit,
  handleAltSubmit,
  handleDocumentUploadChange,
  customNavigationHandler,
  submitDisabled,
  uploadedDoc,
  disableNavigationProceed,
  disableNavigationBack,
  isSubmitted,
  submissionError,
  existingValues,
  initialFormState: preloadedFormState,
  recordId,
}: FormFlowProps) => {
  const dispatch = useDispatch();

  const initialFormState: any = useMemo(() => {
    return constructInitialFormState(configs.views, preloadedFormState);
  }, [configs, preloadedFormState]);

  const [formState, dispatchFormState] = useReducer(
    formStateReducer,
    initialFormState,
  );

  const activeSection = useSelector(activeSectionSelector);
  const previousSection = useSelector(previousSectionSelector);
  const priorExistingRecords = useSelector(priorExistingRecordsSelector);

  const viewsToInsert = uniqueViews(
    configs.views.filter(
      viewsToDiscard(
        formState,
        formId === FORM_FLOWS.SALE || formId === FORM_FLOWS.PORTFOLIO_SALE
          ? priorExistingRecords.sales
          : priorExistingRecords.leases,
        existingValues,
      ),
    ),
  );
  const activeView = viewsToInsert[activeSection];

  const activeViewConfig = getActiveViewConfigs(
    activeView,
    formState,
    existingValues,
  );

  const { viewErrors, errorsToDisplay } = useViewValidation(
    formState[activeViewConfig.viewId],
    formId,
    activeViewConfig.visibleGroups,
    activeViewConfig.validation?.requiresOneOf,
  );

  const findMatchesProps = useMemo(() => {
    return getFindMatchesProps(
      formState,
      activeViewConfig,
      formId === FORM_FLOWS.SALE || formId === FORM_FLOWS.PORTFOLIO_SALE
        ? priorExistingRecords.sales
        : priorExistingRecords.leases,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]) as FindMatchesProps;

  useEffect(() => {
    dispatch(
      initializeFormFlow({
        name: activeViewConfig.viewId,
      }),
    );
    return () => {
      dispatch(resetFormFlow());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubmitted && !submissionError) handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, submissionError]);

  useEffect(() => {
    if (uploadedDoc) {
      dispatchFormState({
        type: 'updateFormStateWithDocs',
        payload: { viewId: activeViewConfig.viewId, uploadedDoc },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedDoc]);

  // <!--- Navigation Flow --->
  const setNewActiveSection = (newSection: number) => {
    const name = viewsToInsert[newSection].viewId;
    dispatch(setFormFlowSection({ index: newSection, name }));
  };

  const controlSectionFlow = () => {
    if (activeSection < viewsToInsert.length - 1) {
      setNewActiveSection(activeSection + 1);
    }
  };

  const controlSectionFinishFlow = () => {
    const skipToScreen = viewsToInsert.findIndex((view) => view.skipToScreen);
    const finalScreen =
      !activeViewConfig.skipToScreen && skipToScreen > 0
        ? skipToScreen
        : viewsToInsert.length - 1;
    setNewActiveSection(finalScreen);
  };

  const goToNextSection = () => {
    if (
      activeView.customNavigation &&
      evaluateConditions(activeView.customNavigation, formState) &&
      customNavigationHandler
    ) {
      customNavigationHandler(activeView, controlSectionFlow, formState);
      return;
    }

    controlSectionFlow();
  };

  const goToPreviousSection = () => {
    if (activeSection === 0) {
      handleClose();
    }

    if (activeSection === viewsToInsert.length - 1) {
      setNewActiveSection(previousSection);
      return;
    }

    if (activeSection > 0) {
      setNewActiveSection(activeSection - 1);
    }
  };

  const goDirectToView = (destinationViewId: string) => {
    const destinationViewIndex = viewsToInsert.findIndex(
      (view: ViewConfig) => view.viewId === destinationViewId,
    );

    if (destinationViewIndex >= 0) setNewActiveSection(destinationViewIndex);
  };

  const goToFinish = () => {
    if (
      activeView.customNavigation &&
      evaluateConditions(activeView.customNavigation, formState) &&
      customNavigationHandler
    ) {
      customNavigationHandler(activeView, controlSectionFinishFlow, formState);
      return;
    }

    controlSectionFinishFlow();
  };
  // <!--- END Navigation Flow --->

  // <!--- Trigger State Change --->
  const handleOnChange: FieldOnChangeHandler = (
    value,
    label,
    id,
    props,
    groupId?,
  ) => {
    dispatchFormState({
      type: 'handleFieldUpdate',
      payload: {
        viewId: activeViewConfig.viewId,
        props,
        label,
        groupId,
        id,
        value,
        initialFormState,
      },
    });
  };

  const onSingleDataSourceChange = (option: SourceOption, path: string) => {
    dispatchFormState({
      type: 'onSingleDataSourceChange',
      payload: {
        path,
        option,
      },
    });
  };

  const onAllDataSourcesChange = (option: SourceOption) => {
    dispatchFormState({
      type: 'onAllDataSourcesChange',
      payload: {
        option,
      },
    });
  };
  // <!--- END Trigger State Change --->

  const submitForm = () => {
    handleSubmit(formState);
  };

  const preventFormDefaultSubmission = (event: React.FormEvent) =>
    event.preventDefault();

  const contextValue = useMemo(() => {
    return {
      initialState: initialFormState,
      viewId: activeViewConfig.viewId,
      formState,
      dispatchFormState,
    };
  }, [formState, dispatchFormState, initialFormState, activeViewConfig.viewId]);

  const altProps = handleAltSubmit
    ? {
        handleAltSubmit: () => {
          handleAltSubmit(formState);
        },
      }
    : {};
  return (
    <StyledForm onSubmit={preventFormDefaultSubmission}>
      <FormSidebar
        parentSection={activeView.parentSection}
        progressSections={configs.progressSections}
        hideProgressSteps={activeView.hideProgressSteps}
        sidebarCardType={configs.sidebarCardType}
        visibleViews={viewsToInsert}
      >
        {renderCard && renderCard()}
      </FormSidebar>

      <StyledFormMain>
        <FormStateContext.Provider value={contextValue}>
          {submissionError && (
            <FormFlowErrors>
              <FormErrors
                apiErrors={
                  Array.isArray(submissionError)
                    ? submissionError
                    : [submissionError]
                }
              />
            </FormFlowErrors>
          )}
          {activeViewConfig.viewType &&
          activeViewConfig.viewType === VIEW_TYPES.LESSOR_VIEW ? (
            <LessorView
              viewConfig={activeViewConfig}
              formState={formState}
              onChange={handleOnChange}
              viewErrors={errorsToDisplay}
              existingPriorRecords={findMatchesProps}
            />
          ) : (
            <FormView
              viewConfig={activeViewConfig}
              configs={configs}
              formState={formState}
              onChange={handleOnChange}
              viewErrors={errorsToDisplay}
              handleDocumentUploadChange={handleDocumentUploadChange}
              goDirectToView={goDirectToView}
              onSourceChange={onSingleDataSourceChange}
              onAllSourcesChange={onAllDataSourcesChange}
              existingPriorRecords={findMatchesProps}
              existingValues={existingValues}
              recordId={recordId}
            />
          )}
        </FormStateContext.Provider>
      </StyledFormMain>
      <FormNavigation
        onPrevClick={goToPreviousSection}
        onNextClick={goToNextSection}
        onGoToFinishClick={goToFinish}
        handleSubmit={submitForm}
        {...altProps}
        hasErrors={viewErrors.length !== 0 || false}
        isLastView={activeSection === viewsToInsert.length - 1}
        activeView={activeView}
        activeViewIndex={activeSection}
        totalViews={viewsToInsert.length}
        disableNavigationProceed={disableNavigationProceed}
        disableNavigationBack={disableNavigationBack}
        submitDisabled={submitDisabled}
        formState={formState}
      />
    </StyledForm>
  );
};

FormFlowBeta.whyDidYouRender = false;
export default FormFlowBeta;
