import { GlobalApiFailError } from 'store/actions/types';

export enum EpcSelectActionTypes {
  EPC_SELECT_QUERY = 'EPC_SELECT_QUERY',
  EPC_SELECT_API_SUCCESS = 'EPC_SELECT_API_SUCCESS',
  EPC_SELECT_API_FAIL = 'EPC_SELECT_API_FAIL',
  EPC_SELECT_RESET = 'EPC_SELECT_RESET',
  EPC_SELECT_TOGGLE_VERTICAL = 'EPC_SELECT_TOGGLE_VERTICAL',
}

export type EpcSelectQueryActionPayload = {
  searchTerm: string;
  page: number;
};

export type EpcSelectQueryAction = {
  type: EpcSelectActionTypes.EPC_SELECT_QUERY;
  payload: EpcSelectQueryActionPayload;
};

export type EpcSelectApiSuccessAction = {
  type: EpcSelectActionTypes.EPC_SELECT_API_SUCCESS;
  payload: EpcSelectResult;
};

export type EpcSelectApiFailAction = {
  type: EpcSelectActionTypes.EPC_SELECT_API_FAIL;
  payload: GlobalApiFailError;
};

export type EpcSelectResetAction = {
  type: EpcSelectActionTypes.EPC_SELECT_RESET;
};

export type EpcSelectAthenaResult = {
  id: string;
  address: string;
  floorArea: string;
  type: string;
  ratingNumber: string;
  ratingBand: string;
  assessmentOrLodgementDate: Date;
};

export type EpcSelectAthenaResultMeta = {
  page: number;
  pages: number;
  pageSize: number;
};

export type EpcSelectResult = {
  results: EpcSelectAthenaResult[];
  meta?: EpcSelectAthenaResultMeta;
};

export type EpcSelectActions =
  | EpcSelectApiSuccessAction
  | EpcSelectApiFailAction
  | EpcSelectQueryAction
  | EpcSelectResetAction;
