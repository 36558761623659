import { TableHeaderCellProps } from 'components/Table/types';

export const EPC_SELECT_TABLE_COPY = {
  display: 'DEC',
  nonDomestic: 'Non Domestic',
  headers: {
    address: 'Address',
    type: 'Type',
    date: 'Date',
    floorArea: 'Floor Area (Sq Ft)',
    rating: 'Rating',
  },
};

export const EPC_RESULTS_TABLE_HEADERS: TableHeaderCellProps[] = [
  {
    title: '',
    key: 'view',
    isHeadingHidden: true,
  },
  {
    title: EPC_SELECT_TABLE_COPY.headers.address,
    isWide: true,
    isVisible: true,
    key: 'address',
  },
  {
    title: EPC_SELECT_TABLE_COPY.headers.floorArea,
    isVisible: true,
    key: 'floorArea',
  },
  {
    title: EPC_SELECT_TABLE_COPY.headers.rating,
    isVisible: true,
    key: 'rating',
  },
  {
    title: EPC_SELECT_TABLE_COPY.headers.date,
    isWide: true,
    isVisible: true,
    key: 'date',
  },
  {
    title: EPC_SELECT_TABLE_COPY.headers.type,
    isWide: true,
    isVisible: true,
    key: 'type',
  },
];
