/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import pathOr from 'ramda/src/pathOr';

import { getMinMaxDateProps } from 'utils/date';
import { RootState } from 'store/rootReducer';
import { getVerticalSelector } from 'store/selectors/detailsSelectors';
import { INPUT_FORMAT_TYPE } from 'globalConstants';
import Radio from 'components/Forms/Inputs/Radio/Radio';
import LargeRadio from 'components/Forms/Inputs/LargeRadio/LargeRadio';
import Select from 'components/Forms/Inputs/Select/Select';
import UseClauseSelect from 'components/Forms/Inputs/UseClauseSelect/UseClauseSelect';
import TextArea from 'components/Forms/Inputs/Textarea/Textarea';
import TextInput from 'components/Forms/Inputs/TextInput/TextInput';
import Checkbox from 'components/Forms/Inputs/Checkbox/Checkbox';
import CountrySelect from 'components/Forms/Inputs/CountrySelect/CountrySelect';
import FloorLevelSelect from 'connected/FloorSelect/FloorLevelSelect';
import FloorSelect from 'connected/FloorSelect/FloorSelect';
import PartitionSelect from 'connected/FloorSelect/PartitionSelect';
import DatePickerInput from 'components/Forms/Inputs/DatePicker/DatePicker';
import UploadDelete from 'components/Forms/Inputs/UploadDelete/UploadDelete';
import LargeCheckbox from 'components/Forms/Inputs/LargeCheckbox/LargeCheckbox';
import CompanySelect from 'connected/CompanySelect/CompanySelect';
import MultiSelect from 'components/Forms/Inputs/MultiSelect/MultiSelect';
import MergeRecordsField from 'components/Forms/Inputs/MergeRecords/MergeRecords';
import MultilineFieldsetWithSingleAdd from 'connected/FormFlow/FormMultiline/MultiLineWithSingleAdd';
import MultilineWithBatch from 'connected/FormFlow/FormMultiline/MultiLineWithBatch';
import MultilineWithRepeat from 'connected/FormFlow/FormMultiline/MultiLineWithRepeat';
import MultiLineDemise from 'connected/FormFlow/FormMultiline/MutliLineDemise';

import DateLength from 'components/Forms/Inputs/DateLength/DateLength';
import MultiLineWithBatchOrdered from 'connected/FormFlow/FormMultiline/MultiLineWithBatchOrdered';
import FloorPartitionMultiline from 'connected/FormFlow/FormMultiline/FloorPartitionMultiline';

import { Option } from 'components/Forms/types';
import { LeaseRecord } from 'pages/Details/types';
import RenewLeases from 'connected/FormFlow/RenewLeases/RenewLeases';
import RelativeDatePicker from 'components/Forms/Inputs/DatePicker/RelativeDatePicker';

import EpcSelect from 'connected/EpcSelect/EpcSelect';
import { FIELD_COMPONENTS } from '../types';
import { getLineConfig, getStateValueAtPath } from '../services/formState';

import { FormStateContext } from '../context';
import { FormFieldProps } from './types';
import { STATE_UPDATE_TYPES } from './constants';
import {
  convertToPercentage,
  convertValueToArray,
  getCustomPropsType,
  getDefaultValue,
  getGroupProps,
  stripFormatValueAndHandle,
} from './helpers';

const FormField = ({
  type,
  name,
  label,
  options,
  prompt,
  description,
  value,
  validation,
  readOnly,
  onDocumentUploadChange,
  fileInputType,
  fields,
  lineLimit,
  inlineLabels,
  groupId,
  itemProps,
  lineValues,
  hasDefaultValue,
  isGroup = false,
  isInline = false,
  handleOnChange,
  direction,
  subtitle,
  existingValues,
  decimals,
  hideBlankOption,
  rowFields,
  setRowFields,
  columns,
  context,
  optionsFilter,
}: FormFieldProps) => {
  const vertical = useSelector(getVerticalSelector);
  const newRecordId = useSelector(
    ({ newRecord }: RootState) => newRecord.recordId,
  );
  const contextFormState = useContext(FormStateContext);

  let itemOptions: any = [];

  if (options) {
    itemOptions = options;
  }

  let defaultValue = getDefaultValue(existingValues);
  let defaultSelectedValue = value;

  if (defaultValue) {
    defaultSelectedValue = defaultValue;
  }

  const updateFormState = (
    updateType: STATE_UPDATE_TYPES,
    payloadLabel: string,
    formProps: any,
  ) => {
    contextFormState.dispatchFormState({
      type: updateType,
      payload: {
        viewId: contextFormState.viewId,
        initialFormState: contextFormState.initialState,
        props: itemProps,
        label: payloadLabel,
        ...formProps,
      },
    });
  };

  const onDelete = (isCountry = false) => {
    const groupProps = getGroupProps(isCountry, groupId, name);
    const formProps = { props: itemProps, ...groupProps };
    updateFormState(STATE_UPDATE_TYPES.DELETE, label, formProps);
  };

  const onChangeMultiSelect = (
    val: Option[],
    multiSelectComponentType?: FIELD_COMPONENTS,
  ) => {
    if (handleOnChange) {
      handleOnChange(val);
    } else {
      const groupProps = isGroup ? { groupId } : {};
      const fieldItemProps = multiSelectComponentType
        ? { ...itemProps, multiSelectComponentType }
        : itemProps;
      const fieldProps = {
        ...groupProps,
        value: val,
        id: name,
        props: fieldItemProps,
      };
      updateFormState(STATE_UPDATE_TYPES.UPDATE, label, fieldProps);
    }
  };

  const onChange = (
    val: string | string[] | number | Date,
    fieldLabel?: string,
    selectComponentType?: FIELD_COMPONENTS,
  ) => {
    if (handleOnChange) {
      handleOnChange(val, fieldLabel);
    } else {
      const groupProps = isGroup ? { groupId } : {};
      const fieldItemProps = selectComponentType
        ? { ...itemProps, selectComponentType }
        : itemProps;
      const fieldProps = {
        ...groupProps,
        value: val,
        id: name || groupId,
        props: fieldItemProps,
      };
      const itemLabel = fieldLabel || label;
      updateFormState(STATE_UPDATE_TYPES.UPDATE, itemLabel, fieldProps);
    }
  };

  const getDate = (refPath: any) => {
    if (refPath) {
      return getStateValueAtPath(contextFormState.formState, refPath);
    }

    return null;
  };

  const lineConfig = getLineConfig(fields, contextFormState.formState);
  const lineConfigTypes = [
    FIELD_COMPONENTS.MULTILINE_FLOOR_PARTITION,
    FIELD_COMPONENTS.MULTILINE_DEMISE,
    FIELD_COMPONENTS.RENEW_OTHER_LEASES,
    FIELD_COMPONENTS.MULTILINE_BATCH,
    FIELD_COMPONENTS.MULTILINE_BATCH_ORDERED,
    FIELD_COMPONENTS.MULTILINE_REPEAT,
  ];
  const requiresLineConfig = lineConfigTypes.includes(type);

  if (requiresLineConfig && !lineConfig) {
    return <></>;
  }

  const isHidden = type === FIELD_COMPONENTS.HIDDEN_TEXT_INPUT;

  const { additionalProps, componentType } = getCustomPropsType(type, decimals);

  let checkboxAlignment: 'right' | 'left' | undefined = 'right';

  if (type === FIELD_COMPONENTS.CHECKBOX) {
    checkboxAlignment = 'left';
  }

  switch (type) {
    case FIELD_COMPONENTS.CHECKBOX:
    case FIELD_COMPONENTS.NAVIGATION_CHECKBOX: {
      return (
        <Checkbox
          onKeyPress={(e) => onChange(e)}
          onChange={(e, isChecked) => onChange(isChecked ? e.target.value : '')}
          label={label}
          value={name}
          name={name}
          isChecked={!!value}
          validation={validation}
          prompt={prompt}
          align={checkboxAlignment}
          subtitle={subtitle}
          verticalAlign="center"
          disabled={readOnly}
        />
      );
    }

    case FIELD_COMPONENTS.RADIO: {
      return (
        <Radio
          label={label}
          description={prompt}
          handleOnChange={onChange}
          items={itemOptions}
          name={name}
          selectedValue={value}
          validation={validation}
          isInline={isInline}
          direction={direction}
        />
      );
    }

    case FIELD_COMPONENTS.LARGE_RADIO: {
      return (
        <LargeRadio
          label={label}
          description={prompt}
          handleOnChange={onChange}
          items={itemOptions}
          name={name}
          selectedValue={value}
          validation={validation}
        />
      );
    }

    case FIELD_COMPONENTS.LARGE_CHECKBOX: {
      const handleLargeCheckboxChange = (e: string[]) => {
        const selectedCheckboxes = e.toString();
        onChange(selectedCheckboxes);
      };
      return (
        <LargeCheckbox
          label={label}
          description={prompt}
          handleOnChange={handleLargeCheckboxChange}
          items={itemOptions}
          name={name}
          selectedValue={value}
          validation={validation}
        />
      );
    }

    case FIELD_COMPONENTS.SELECT: {
      const selectedValue = options?.find(
        (option) => option.value === `${value}` || option.value === value,
      );

      return (
        <Select
          label={label}
          onSelectChange={(e) => onChange(e.value, e.label)}
          options={itemOptions}
          name={name}
          validation={validation}
          selectedValue={selectedValue}
          hasDefaultValue={hasDefaultValue}
          hideBlankOption={hideBlankOption}
          prompt={prompt}
          disabled={readOnly}
        />
      );
    }

    case FIELD_COMPONENTS.DYNAMIC_SELECT: {
      if (options && optionsFilter) {
        itemOptions = optionsFilter(options, context);
      }

      const selectedValue = options?.find(
        (option) => option.value === `${value}` || option.value === value,
      );

      return (
        <Select
          label={label}
          onSelectChange={(e) => onChange(e.value, e.label)}
          options={itemOptions}
          name={name}
          validation={validation}
          selectedValue={selectedValue}
          hasDefaultValue={hasDefaultValue}
          hideBlankOption={hideBlankOption}
          prompt={prompt}
          disabled={readOnly}
        />
      );
    }

    case FIELD_COMPONENTS.USE_CLAUSE_SELECT: {
      return (
        <UseClauseSelect
          name={name}
          label={label}
          selectedValue={value}
          onChange={(values) => onChangeMultiSelect(values)}
        />
      );
    }

    case FIELD_COMPONENTS.MULTI_SELECT: {
      return (
        <MultiSelect
          label={label}
          onChange={(values) => onChangeMultiSelect(values)}
          options={itemOptions}
          name={name}
          selectedValue={value}
        />
      );
    }

    case FIELD_COMPONENTS.DYNAMIC_MULTI_SELECT: {
      if (options && optionsFilter) {
        itemOptions = optionsFilter(options, context);
      }

      return (
        <MultiSelect
          label={label}
          onChange={(values) => onChangeMultiSelect(values)}
          options={itemOptions}
          name={name}
          selectedValue={value}
        />
      );
    }

    case FIELD_COMPONENTS.TEXTAREA: {
      return (
        <TextArea
          value={value}
          prompt={prompt}
          onChange={(event) => onChange(event.target.value)}
          label={label}
          name={name}
          validation={validation}
          isInline={isInline}
        />
      );
    }

    case FIELD_COMPONENTS.TEXT_INPUT:
    case FIELD_COMPONENTS.HIDDEN_TEXT_INPUT: {
      return (
        <TextInput
          value={value}
          onChange={(event) => onChange(event.target.value)}
          label={label}
          name={name}
          validation={validation}
          isDisabled={readOnly}
          componentType={componentType}
          isHidden={isHidden}
          prompt={prompt}
          {...additionalProps}
        />
      );
    }

    case FIELD_COMPONENTS.NUMERIC_INPUT: {
      return (
        <TextInput
          formatInput={INPUT_FORMAT_TYPE.NUMERIC}
          value={value}
          onChange={stripFormatValueAndHandle(onChange)}
          label={label}
          name={name}
          validation={validation}
          isDisabled={readOnly}
          componentType={componentType}
          isHidden={isHidden}
          {...additionalProps}
        />
      );
    }

    case FIELD_COMPONENTS.INTEGER:
    case FIELD_COMPONENTS.PRICE_INPUT:
    case FIELD_COMPONENTS.DECIMAL_PRICE_INPUT:
    case FIELD_COMPONENTS.YEAR_INPUT:
    case FIELD_COMPONENTS.MONTH_INPUT:
    case FIELD_COMPONENTS.SQUARE_FT_INPUT:
    case FIELD_COMPONENTS.PRICE_PER_SQ_FT_INPUT: {
      return (
        <TextInput
          value={value}
          onChange={stripFormatValueAndHandle(onChange)}
          label={label}
          name={name}
          validation={validation}
          isDisabled={readOnly}
          {...additionalProps}
          componentType={componentType}
        />
      );
    }

    case FIELD_COMPONENTS.DATE_PICKER: {
      const minDate = getDate(validation?.minValue?.ref);
      const maxDate = getDate(validation?.maxValue?.ref);

      const minMaxProps = getMinMaxDateProps(minDate, maxDate);

      const updatedValidation: any = {
        ...validation,
        minDate,
        maxDate,
      };

      const hasMinValue = updatedValidation && updatedValidation.minValue;
      const hasMaxValue = updatedValidation && updatedValidation.maxValue;

      if (hasMinValue) {
        delete updatedValidation.minValue;
      }

      if (hasMaxValue) {
        delete updatedValidation.maxValue;
      }

      return (
        <DatePickerInput
          onChange={(date) => {
            if (onChange) onChange(date);
          }}
          value={value}
          label={label}
          name={name}
          isDisabled={readOnly}
          validation={updatedValidation}
          prompt={prompt}
          {...minMaxProps}
        />
      );
    }

    case FIELD_COMPONENTS.RELATIVE_DATEPICKER: {
      return (
        <RelativeDatePicker
          onChange={onChange}
          value={value}
          label={label}
          name={name}
          isDisabled={readOnly}
        />
      );
    }

    case FIELD_COMPONENTS.PERCENTAGE: {
      return (
        <TextInput
          formatInput={INPUT_FORMAT_TYPE.PERCENTAGE}
          onChange={stripFormatValueAndHandle(onChange, true)}
          label={label}
          name={name}
          value={convertToPercentage(value)}
          validation={validation}
          isDisabled={readOnly}
          componentType={FIELD_COMPONENTS.PERCENTAGE}
        />
      );
    }

    case FIELD_COMPONENTS.FILE_INPUT: {
      return (
        <UploadDelete
          entityId={newRecordId}
          preselectedFileType={fileInputType}
          onDocumentUploadChange={({
            fileDetails,
            fileType,
            filenameToDelete,
            changeType,
          }) => {
            if (fileDetails) onChange(fileDetails.name);

            if (onDocumentUploadChange) {
              onDocumentUploadChange({
                fileDetails,
                fileType,
                filenameToDelete,
                changeType,
              });
            }
          }}
        />
      );
    }

    case FIELD_COMPONENTS.COUNTRY_SELECT: {
      return (
        <CountrySelect
          selectedValue={value}
          name={name}
          validation={validation}
          isDisabled={readOnly}
          onDelete={onDelete}
          onChange={(e: { value: string; label: string }) => onChange(e?.value)}
        />
      );
    }

    case FIELD_COMPONENTS.FLOOR_LEVEL_SELECT: {
      return (
        <FloorLevelSelect
          name={name}
          label={label}
          selectedValue={defaultSelectedValue}
          onChange={onChange}
          validation={validation}
          rowFields={rowFields}
          setRowFields={setRowFields}
          lineValue={lineValues}
        />
      );
    }

    case FIELD_COMPONENTS.FLOOR_SELECT: {
      return (
        <FloorSelect
          selectedValue={defaultSelectedValue}
          value={value}
          name={name}
          validation={validation}
          onChange={(floor: string) => onChange(floor)}
          lineValue={lineValues}
          rowFields={rowFields}
          setRowFields={setRowFields}
        />
      );
    }

    case FIELD_COMPONENTS.PARTITION_SELECT: {
      return (
        <PartitionSelect
          selectedValue={defaultSelectedValue}
          name={name}
          validation={validation}
          onDelete={onDelete}
          onChange={(e: { value: string; label: string }) =>
            onChange(e.value, e.label, FIELD_COMPONENTS.PARTITION_SELECT)
          }
          rowFields={rowFields}
          setRowFields={setRowFields}
          lineValue={lineValues}
        />
      );
    }

    case FIELD_COMPONENTS.COMPANY_SELECT: {
      return (
        <CompanySelect
          selectedValue={convertValueToArray(value)}
          label={label}
          validation={validation}
          name={name}
          isDisabled={readOnly}
          onDelete={onDelete}
          onChange={(e: { value: string; label: string }) =>
            onChange(e.value, label)
          }
          canAddNew
        />
      );
    }

    case FIELD_COMPONENTS.MULTILINE_FIELDSET: {
      const multilineConfig = getLineConfig(
        fields,
        contextFormState.formState,
        vertical,
      );

      defaultValue = getDefaultValue(value);

      if (multilineConfig) {
        return (
          <MultilineFieldsetWithSingleAdd
            lineLimit={lineLimit}
            handleOnChange={onChange}
            lineConfig={multilineConfig}
            formStateItems={defaultValue}
            prompt={prompt}
            multilineTitle={itemProps?.multilineTitle}
            addButton={itemProps?.addButton}
            groupId={groupId}
            minimumLines={itemProps?.minimumLines}
          />
        );
      }

      return null;
    }

    case FIELD_COMPONENTS.MULTILINE_FLOOR_PARTITION: {
      return (
        <FloorPartitionMultiline
          lineLimit={lineLimit}
          handleOnChange={onChange}
          lineConfig={lineConfig!}
          formStateItems={value}
          prompt={prompt}
          columns={columns}
          description={description}
          addBatchLabel={itemProps?.addBatchLabel}
          addButton={itemProps?.addBatchButtonLabel}
          multilineTitle={itemProps?.multilineTitle}
          groupId={groupId}
          multilineType={itemProps?.multilineType}
        />
      );
    }

    case FIELD_COMPONENTS.MULTILINE_DEMISE: {
      const leases = [existingValues, ...existingValues.dealDetails.leases];

      defaultValue = leases.map((lease: any, index) => ({
        index,
      }));

      if (value) {
        defaultValue = value;
      }

      return (
        <MultiLineDemise
          handleOnChange={onChange}
          lineConfig={lineConfig!}
          formStateItems={defaultValue}
          existingValues={existingValues}
        />
      );
    }

    case FIELD_COMPONENTS.RENEW_OTHER_LEASES: {
      let leases = [];

      if (contextFormState.formState.renewalDetails.otherLeases.relatedLeases) {
        leases =
          contextFormState.formState.renewalDetails.otherLeases.relatedLeases;
      }

      if (value) {
        defaultValue = value;
      } else {
        defaultValue = leases.map((lease: LeaseRecord, index: number) => ({
          index,
          leaseId: {
            value: lease.leaseId,
          },
          achievedRent: {
            value: '',
          },
        }));
      }

      return (
        <RenewLeases
          handleOnChange={onChange}
          lineConfig={lineConfig!}
          formStateItems={defaultValue}
          existingValues={existingValues}
          leases={leases}
        />
      );
    }

    case FIELD_COMPONENTS.MULTILINE_BATCH: {
      defaultValue = getDefaultValue(value);

      return (
        <MultilineWithBatch
          lineLimit={lineLimit}
          handleOnChange={onChange}
          lineConfig={lineConfig!}
          formStateItems={defaultValue}
          prompt={prompt}
          description={description}
          addBatchLabel={itemProps?.addBatchLabel}
          addButton={itemProps?.addBatchButtonLabel}
          multilineTitle={itemProps?.multilineTitle}
          groupId={groupId}
        />
      );
    }

    case FIELD_COMPONENTS.MULTILINE_BATCH_ORDERED: {
      return (
        <MultiLineWithBatchOrdered
          lineLimit={lineLimit}
          handleOnChange={onChange}
          lineConfig={lineConfig!}
          formStateItems={defaultValue}
          prompt={prompt}
          columns={columns}
          description={description}
          addBatchLabel={itemProps?.addBatchLabel}
          addButton={itemProps?.addBatchButtonLabel}
          multilineTitle={itemProps?.multilineTitle}
          groupId={groupId}
          multilineType={itemProps?.multilineType}
        />
      );
    }

    case FIELD_COMPONENTS.MULTILINE_REPEAT: {
      defaultValue = getDefaultValue(value);

      return (
        <MultilineWithRepeat
          lineLimit={lineLimit}
          handleOnChange={onChange}
          lineConfig={lineConfig!}
          formStateItems={defaultValue}
          prompt={prompt}
          description={description}
          addRepeatable={itemProps?.addRepeatable}
          addButton={itemProps?.addButton}
          multilineTitle={itemProps?.multilineTitle}
          repeatableKeys={itemProps?.repeatableKeys}
          groupId={groupId}
        />
      );
    }

    case FIELD_COMPONENTS.DATE_LENGTH: {
      const startDate: Date | undefined =
        validation && validation.dependentOn
          ? pathOr(
              undefined,
              [...validation.dependentOn.split('.'), 'value'],
              contextFormState.formState,
            )
          : undefined;

      const updateState = (
        val?: string | number | Date,
        fieldLabel?: string,
        id?: string,
      ) => {
        const payloadLabel = fieldLabel || label;
        const fieldProps = {
          props: itemProps,
          groupId,
          id: id || name,
          value: val,
        };
        updateFormState(STATE_UPDATE_TYPES.UPDATE, payloadLabel, fieldProps);
      };

      return (
        <DateLength
          onChange={updateState}
          selectedValue={value}
          label={label}
          validation={validation}
          name={name}
          startDate={startDate}
          inlineLabels={inlineLabels}
        />
      );
    }

    case FIELD_COMPONENTS.MERGE_RECORDS: {
      const defaultSelectedMergeValue =
        contextFormState.initialState.mergeRecords.mergeRecordsPayload.value;
      return (
        <MergeRecordsField
          defaultValue={defaultSelectedMergeValue}
          handleOnChange={onChange}
        />
      );
    }

    case FIELD_COMPONENTS.EPC_SELECT: {
      return <EpcSelect onChange={onChange} />;
    }

    default: {
      return <></>;
    }
  }
};

export default FormField;
