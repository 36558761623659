import { globalContent } from 'globalConstants';
import { LeaseRecord } from 'pages/Details/types';
import { formatArea, formatDate } from 'services/format';

export const getDate = (lease: LeaseRecord) => {
  if (lease.startDate && lease.endDate) {
    return `${formatDate(lease.startDate)} - ${formatDate(lease.endDate)}`;
  }

  if (lease.startDate) {
    return `${formatDate(lease.startDate)} - ${globalContent.unknown}`;
  }

  return `${globalContent.unknown} - ${formatDate(lease.endDate)}`;
};

export const getSpaceAvailable = (lease: LeaseRecord) => {
  return `${
    lease.minimumLettableArea
      ? formatArea(lease.minimumLettableArea)
      : global.undefined
  } - ${
    lease.maximumLettableArea
      ? formatArea(lease.maximumLettableArea)
      : global.undefined
  }`;
};
