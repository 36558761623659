import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PanelWrapper from 'components/PanelWrapper/PanelWrapper';
import { getUserProfileAction } from 'store/actions/userProfileActions';
import { PanelTheme } from 'components/PanelWrapper/types';
import SortPaginationTable from 'components/Table/SortPaginationTable';
import { NoResultsFound } from 'connected/BasePanel/BasePanelStyled';
import GlobalModalWrapper from 'connected/GlobalModal/GlobalModalWrapper';
import { MODAL_ID } from 'connected/GlobalModal/constants';
import { QUERY_TABLE_HEADERS } from 'pages/Profile/constants';
import {
  getCurrentModalSelector,
  getModalIsOpenSelector,
  getCurrentModalRecordIdSelector,
} from 'store/selectors/globalModalSelectors';
import {
  getUserProfileQueryResults,
  getUserScheduledQueryResults,
} from 'store/selectors/userProfileSelectors';
import { getUsersScheduledQueries } from 'pages/Profile/QueryPanel/helpers';
import { mapQueriesToTableFormattedData } from './helpers';
import ScheduleTeamQuery from './ScheduleTeamQuery';
import { ADMIN_QUERY_PANEL_COPY } from './constants';
import ScheduleTeamQueryResult from './ScheduleTeamQueryResult';

const AdminQueryPanel: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const queries = useSelector(getUserProfileQueryResults);
  const currentModal = useSelector(getCurrentModalSelector);
  const modalIsOpen = useSelector(getModalIsOpenSelector);
  const scheduledQueries = useSelector(getUserScheduledQueryResults);
  const currentRecordId = useSelector(getCurrentModalRecordIdSelector);

  const formattedData = useMemo(() => {
    return queries.map((query) => {
      const scheduledQuery = getUsersScheduledQueries(
        scheduledQueries,
        query.userQueryId,
      );
      return mapQueriesToTableFormattedData(query, scheduledQuery);
    });
  }, [queries, scheduledQueries]);

  return (
    <PanelWrapper
      title={ADMIN_QUERY_PANEL_COPY.heading}
      id="admin-query-panel"
      anchorId="query"
    >
      {!formattedData || formattedData.length === 0 ? (
        <NoResultsFound>{ADMIN_QUERY_PANEL_COPY.noResults}</NoResultsFound>
      ) : (
        <SortPaginationTable
          columns={7}
          rows={formattedData}
          tableId="queryTable"
          headers={QUERY_TABLE_HEADERS}
          useTheme={PanelTheme.DEFAULT}
          tableUseContext="DETAILS"
        />
      )}
      {modalIsOpen &&
        currentModal === MODAL_ID.SCHEDULE_TEAM_QUERY &&
        currentRecordId && (
          <GlobalModalWrapper
            modalId={MODAL_ID.SCHEDULE_TEAM_QUERY}
            isClickableOutsideToClose={false}
            lightTheme
            size="fullsize"
          >
            <ScheduleTeamQuery recordId={currentRecordId} />
          </GlobalModalWrapper>
        )}
      {modalIsOpen && currentModal === MODAL_ID.SCHEDULE_TEAM_QUERY_RESULT && (
        <GlobalModalWrapper
          modalId={MODAL_ID.SCHEDULE_TEAM_QUERY_RESULT}
          hasCloseButton={false}
          lightTheme
          isClickableOutsideToClose={false}
          size="large"
        >
          <ScheduleTeamQueryResult />
        </GlobalModalWrapper>
      )}
    </PanelWrapper>
  );
};

export default AdminQueryPanel;
