import {
  PrimaryImageActions,
  PrimaryImageActionTypes,
} from '../actions/primaryImageActions';

export type PrimaryImageState = {
  isLoading: boolean;
  error: string;
};

export const initialState: PrimaryImageState = {
  isLoading: false,
  error: '',
};

const primaryImageReducer = (
  state = initialState,
  action: PrimaryImageActions,
) => {
  switch (action.type) {
    case PrimaryImageActionTypes.SET_PRIMARY_IMAGE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PrimaryImageActionTypes.SET_PRIMARY_IMAGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case PrimaryImageActionTypes.SET_PRIMARY_IMAGE_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    default:
      return state;
  }
};

export default primaryImageReducer;
