import { AuthenticatedRequestObservable } from 'apis/request';
import {
  AddEpcAction,
  AddEpcActionTypes,
} from 'connected/EpcCertificatesPanel/AddEpc/types';
import { endpoints } from 'globalConstants';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { addEpcFail, addEpcSuccess } from 'store/actions/addEpcActions';

type AddEpcEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const addEpcEpic: Epic = (
  action$,
  state$,
  { authRequest }: AddEpcEpicDependencies,
) =>
  action$.pipe(
    ofType(AddEpcActionTypes.ADD_EPC),
    mergeMap((action: AddEpcAction) => {
      const url = endpoints.epcAdd;

      return authRequest(state$, {
        url,
        method: 'PUT',
        body: action.payload,
      })().pipe(
        map((ajaxResponse) => {
          if (ajaxResponse.status > 200) {
            throw new Error(`error loading ${url}`);
          }
          return addEpcSuccess();
        }),
      );
    }),
    catchError((error) => of(addEpcFail(error))),
  );

export default addEpcEpic;
